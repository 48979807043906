import moment from "moment";
export const COLUMNS = [
  {
    Header: "Campanha",
    accessor: "campanha",
  },
  {
    Header: "Empresa",
    accessor: "empresa",
  },
  {
    Header: "Tipo Pagamento",
    accessor: "tipoRegra",
  },
  {
    Header: "Sistema",
    accessor: "sistema",
  },
  {
    Header: "Prêmio $",
    accessor: "valor",
  },
  {
    Header: "Usuário Aceite",
    accessor: "usuarioAceite",
  },
  {
    Header: "Data Aceite",
    accessor: "dataAceito",
    Cell: ({ value }) => {
      return moment(value).format("DD/MM/YYYY");
    },
    sortType: "datetime",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Data Conferência",
    accessor: "dataAprovacao",
    Cell: ({ value }) => {
      return value ? moment(value).format("DD/MM/YYYY") : ""
    },
    sortType: "datetime",
  },

  {
    Header: "Usuário Conferência",
    accessor: "usuarioAprovador",
  },
];

export const COLUMNS_DETALHAMENTO = [
  {
    Header: "Campanha",
    accessor: "campanha",
  },
  {
    Header: "Favorecido",
    accessor: "favorecido",
  },
  {
    Header: "Hierarquia",
    accessor: "hierarquia",
  },
  {
    Header: "Status favorecido",
    accessor: "status",
  },
  {
    Header: "Empresa",
    accessor: "empresa",
  },
  {
    Header: "Tipo pagamento",
    accessor: "tipoRegra",
  },
  {
    Header: "Sistema",
    accessor: "sistema",
  },
  {
    Header: "Total pago $",
    accessor: "valor",
  },
];

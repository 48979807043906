import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    campanhaPremiacaoMetaProdutividade: [
        {
            hierarquia: {
                value: '0',
                label: ''
            },
            canalId: '',
            parametroMedidaCampanhaId: '',
            valorMeta : '0',
            medidaPremiacaoCampanhaId: '',
            valorPremio: '0',
            campanhaPremiacaoMetaSuperacao: [],
            campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
        }
    ],
    idRegra: '',
    regrasElegiveis: [],
    invalid: {
        descricaoRegra: false,
        valorInicial: false,
        valorFinal: false,
        producaoReceita: false,
        valorQuantidade: false,
        tipoPremiacao: false,
    },
    copy: {},
    hierarquiasProprio: [],
    hierarquiasTerceiro: [],
    allHierarquias: [],
    convenios: [],
    bancos: [],
    canais: [],
    medidasPremiacao: [],
    parametrosMedida: [],
    grupos: [],
    produtos: [],
    tiposParceria: [],
    tiposOperacao: [],
    regrasPremiacao: [],
    regrasVinculadas: [],
    allRegras: []
}

export default function eventosCampanha(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_ALL_REGRAS:
                    return { ...state, allRegras: action.value }
                case TYPES.SET_META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade: {...state.campanhaPremiacaoMetaProdutividade, [action.field] : action.value} }
                case TYPES.META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade:  action.value }
                case TYPES.CLEAN_META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade:   [
                        {
                            hierarquia: {
                                value: '0',
                                label: ''
                            },
                            canalId: '',
                            parametroMedidaCampanhaId: '',
                            valorMeta : '0',
                            medidaPremiacaoCampanhaId: '',
                            valorPremio: '0',
                            campanhaPremiacaoMetaSuperacao: [],
                            campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
                        }
                    ] }
                case TYPES.SET_REGRAS_VINCULADAS:
                    return { ...state, regrasVinculadas: action.value }
                case TYPES.SET_ID_REGRA:
                    return { ...state, idRegra: action.value }
                case TYPES.SET_REGRAS_ELEGIVEIS:
                    return { ...state, regrasElegiveis: action.value }
                case TYPES.REGRAS_PREMIACAO:
                    return { ...state, regrasPremiacao: action.value }
                case TYPES.HIERARQUIAS_PROPRIO:
                    return { ...state, hierarquiasProprio: action.value }
                case TYPES.HIERARQUIAS_TERCEIRO:
                    return { ...state, hierarquiasTerceiro: action.value }
                case TYPES.ALL_HIERARQUIAS:
                    return { ...state, allHierarquias: action.value }
                case TYPES.CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.SET_MEDIDAS_PREMIACAO:
                    return { ...state, medidasPremiacao: action.value }
                case TYPES.SET_PARAMETROS_MEDIDA:
                    return { ...state, parametrosMedida: action.value }
                case TYPES.PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.TIPOS_PARCERIA:
                    return { ...state, tiposParceria: action.value }
                case TYPES.TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.ITEM:
                    return { ...state, itemEvento: action.value }
                case TYPES.COPY:
                    return { ...state, copy: action.value }
                case TYPES.INVALID_ITEM:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_ITEM:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, itemEvento: {
                            id: '',
                            descricaoRegra: '',
                            valorInicial: '',
                            valorFinal: '',
                            canalVendaId: '0',
                            tipoParceriaId: '0',
                            produtoId: '0',
                            bancoId: '0',
                            convenioId: '0',
                            tipoOperacaoId: '0',
                            hierarquiaId: '0',
                            grupoId: '0',
                            tipoPremiacao: '0',
                            premiacao: '0',
                            valorQuantidade: '0',
                            producaoReceita: '0',
                            headCount: false,
                            impedeRepasse: false,
                            flagRegraPrincipal: false
                        }
                    }
                case TYPES.CLEAN_ALL_REGISTER:
                    return {
                        ...state,
                        campanhaPremiacaoMetaProdutividade: [
                            {
                                hierarquia: {
                                    value: '0',
                                    label: ''
                                },
                                canalId: '',
                                parametroMedidaCampanhaId: '',
                                valorMeta : '0',
                                medidaPremiacaoCampanhaId: '',
                                valorPremio: '0',
                                campanhaPremiacaoMetaSuperacao: [],
                                campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
                            }
                        ],
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    email: {
        tipoEmailId: "0",
        nome: "",
        id: null,
        flagPrincipal: false
    },
    telefone: {
        tipoTelefoneId: "0",
        numero: "",
        ddd: "",
        id: null,
        principal: false
    },
    emails: [],
    telefones: [],
    tiposEmails: [],
    tiposTelefones: [],
    seeRegister: false
}

export default function personasContato(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {

                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        emails: [],
                        email: INITIAL_STATE.email,
                        telefones: INITIAL_STATE.telefones
                    }

                case TYPES.CLEAN_TELEFONE:
                    return { ...state, telefone: INITIAL_STATE.telefone }

                case TYPES.CLEAN_EMAIL:
                    return { ...state, email: INITIAL_STATE.email }

                case TYPES.EMAILS:
                    return { ...state, emails: action.value }

                case TYPES.TELEFONES:
                    return { ...state, telefones: action.value }

                case TYPES.SET_TELEFONE:
                    return { ...state, telefone: {...state.telefone, [action.field] : action.value} }

                case TYPES.GET_TIPOS_EMAIL:
                    return { ...state, tiposEmails: action.value }

                case TYPES.GET_TIPOS_TELEFONE:
                    return { ...state, tiposTelefones: action.value }

                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }

                case TYPES.SET_EMAIL:
                    return { ...state, email: action.value }
                    
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import * as actions from "../../../store/modules/campanhaPremiacao/campanha/actions";
import * as actionsEvento from "../../../store/modules/campanhaPremiacao/eventos/actions";
import * as controller from "../../../controllers/premiacaoController/eventosController";

import { Select, Switch } from "../../../components/Inputs/Input";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdAutoGraph } from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import { useMemo, useState } from "react";
import { ModalRegraProdutividade } from "./ModalRegraProdutividade";
import { useEffect } from "react";
import { notifyWarn } from "../../../components/message/message";
import { Tooltip } from "@material-ui/core";
import { usePagination, useSortBy, useTable } from "react-table";
import ReactPaginate from "react-paginate";
import { COLUMNS_REGRA_PRODUTIVIDADE } from "../constants/columns";

export function RegraProdutividade() {
  const {
    regraProdutividade,
    item,
    seeRegister,
    bancos,
    convenios,
    tiposOperacao,
    tiposParceria,
    produtos,
    centrosCusto,
    tiposComissao,
  } = useSelector((state) => state.campanhaPremiacao);
  const {
    campanhaPremiacaoMetaProdutividade,
    allHierarquias,
    medidasPremiacao,
  } = useSelector((state) => state.eventosCampanha);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    if (isModalOpen) {
      actions.cleanRegraProdutividade();
    }
    setIsModalOpen(!isModalOpen);
  };

  const [currentRegraProdutividadeIndex, setCurrentRegraProdutividadeIndex] =
    useState({});
  const [currentRegraProdutividade, setCurrentRegraProdutividade] = useState({
    id: null,
    name: "",
  });

  async function onChangeMetaProdutividade(
    field,
    value,
    indexMeta,
    isAddNewHierarquia
  ) {
    let currentMetaProdutividade =
      campanhaPremiacaoMetaProdutividade[indexMeta];
    currentMetaProdutividade = {
      ...currentMetaProdutividade,
      [field]: value,
    };

    let newStringMetaProdutividade = JSON.stringify(
      campanhaPremiacaoMetaProdutividade
    );
    let newMetaProdutividade = JSON.parse(newStringMetaProdutividade);
    newMetaProdutividade[indexMeta] = currentMetaProdutividade;
    actionsEvento.metaProdutividade(newMetaProdutividade);

    let itemCampanha = item.campanhaPremiacaoRegras;

    itemCampanha[
      currentRegraProdutividadeIndex
    ].campanhaPremiacaoMetaProdutividade[indexMeta] =
      newMetaProdutividade[indexMeta];

    let newRegrasProdutividade = itemCampanha;
    actions.setItem("campanhaPremiacaoRegras", newRegrasProdutividade);

    if (isAddNewHierarquia) {
      newMetaProdutividade.push({
        hierarquia: {
          value: "0",
          label: "",
        },
        canalId: "",
        parametroMedidaCampanhaId: "",
        valorMeta: "0",
        medidaPremiacaoCampanhaId: "",
        valorPremio: "0",
        campanhaPremiacaoMetaSuperacao: [],
        campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
      });
      actionsEvento.metaProdutividade(newMetaProdutividade);
      itemCampanha[
        currentRegraProdutividadeIndex
      ].campanhaPremiacaoMetaProdutividade[indexMeta + 1] =
        newMetaProdutividade[indexMeta + 1];

      let newRegrasProdutividade = itemCampanha;
      actions.setItem("campanhaPremiacaoRegras", newRegrasProdutividade);
    }
  }

  async function handleAddNewHierarquia() {
    let newMetaProdutividade = campanhaPremiacaoMetaProdutividade;
    newMetaProdutividade.push({
      hierarquia: {
        value: "0",
        label: "",
      },
      canalId: "",
      parametroMedidaCampanhaId: "",
      valorMeta: "0",
      medidaPremiacaoCampanhaId: "",
      valorPremio: "0",
      campanhaPremiacaoMetaSuperacao: [],
      campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
    });
    actionsEvento.metaProdutividade(newMetaProdutividade);

    item.campanhaPremiacaoRegras[
      currentRegraProdutividadeIndex
    ].campanhaPremiacaoMetaProdutividade = newMetaProdutividade;

    let newRegrasProdutividade = item.campanhaPremiacaoRegras;
    actions.setItem("campanhaPremiacaoRegras", newRegrasProdutividade);
  }

  async function handleSaveMetaProdutividade(
    metaProdutividade,
    index,
    isAddNewHierarquia
  ) {
    if (metaProdutividade.canalId === "0") {
      notifyWarn("É necessário informar o canal.");
      return { success: false };
    }
    if (metaProdutividade.hierarquia.value === "0") {
      notifyWarn("É necessário informar a hierarquia.");
      return { success: false };
    }
    if (metaProdutividade.parametroMedidaCampanhaId === "0") {
      notifyWarn("É necessário informar o parâmetro de medida.");
      return { success: false };
    }
    if (metaProdutividade.valorMeta === "") {
      notifyWarn("É necessário informar o valor mínimo.");
      return { success: false };
    }
    if (metaProdutividade.medidaPremiacaoCampanhaId === "0") {
      notifyWarn("É necessário informar a medida de premiação.");
      return { success: false };
    }
    if (!metaProdutividade.id) {
      const response = await controller.createMetaProdutividade(
        metaProdutividade,
        item.campanhaPremiacaoRegras[currentRegraProdutividadeIndex].id
      );
      if (response.success) {
        await onChangeMetaProdutividade(
          "id",
          response.id,
          index,
          isAddNewHierarquia
        );
        return response;
      }
    } else {
      const response = await controller.updateMetaProdutividade(
        metaProdutividade,
        item.campanhaPremiacaoRegras[currentRegraProdutividadeIndex].id
      );
      return response;
    }
  }

  async function handleDeleteMetaProdutividade(metaProdutividade, index) {
    let isSucceeded = true;
    if (metaProdutividade.id) {
      isSucceeded = await controller.deleteMetaProdutividade(
        metaProdutividade.id
      );
    }
    if (isSucceeded) {
      item.campanhaPremiacaoRegras[
        currentRegraProdutividadeIndex
      ].campanhaPremiacaoMetaProdutividade.splice(index, 1);
      actionsEvento.metaProdutividade(
        item.campanhaPremiacaoRegras[currentRegraProdutividadeIndex]
          .campanhaPremiacaoMetaProdutividade
      );
    }
  }

  useEffect(() => {
    controller.getCanais();
    controller.getAllHierarquias();
    controller.getHierarquiasProprio();
    controller.getHierarquiasTerceiro();
    controller.getParametrosMedida();
    controller.getMedidasPremiacao();
  }, []);

  async function handleAddRegra() {
    const isSuccess = await controller.createRegraProdutividade(
      regraProdutividade,
      item.id
    );
    if (isSuccess.success) {
      let instituição =
        bancos.find((banco) => banco.id === regraProdutividade.bancoId) ?? "";
      let convenio =
        convenios.find(
          (convenio) => convenio.id === regraProdutividade.convenioId
        ) ?? "";
      let tipoOperacao =
        tiposOperacao.find(
          (operacao) => operacao.id === regraProdutividade.tipoOperacaoId
        ) ?? "";
      let tipoParceria =
        tiposParceria.find(
          (parceria) => parceria.id === regraProdutividade.tipoParceriaId
        ) ?? "";
      let produto =
        produtos.find(
          (produto) => produto.id === regraProdutividade.produtoId
        ) ?? "";
      let centroCusto =
        centrosCusto.find(
          (centroCusto) => centroCusto.id === regraProdutividade.centroCustoId
        ) ?? "";

      let metaGlobal = regraProdutividade.metaGlobal;
      let metaPrincipal = regraProdutividade.MetaPrincipal;

      regraProdutividade.id = isSuccess.id;
      const arrString = JSON.stringify(item.campanhaPremiacaoRegras);
      const arr = JSON.parse(arrString);
      arr.push({
        ...regraProdutividade,
        instituição: instituição.nome,
        convenio: convenio.descricao,
        tipoOperacao: tipoOperacao.descricao,
        tipoParceria: tipoParceria.nome,
        produto: produto.nome,
        centroCusto: centroCusto.descricao,
        metaGlobal: metaGlobal,
        MetaPrincipal: metaPrincipal,
        campanhaPremiacaoRegraParametroBancos: [],
        campanhaPremiacaoRegraParametroTipoOperacoes: [],
        campanhaPremiacaoRegraParametroTipoParcerias: [],
        campanhaPremiacaoRegraParametroCentroCustos: [],
        campanhaPremiacaoRegraParametroProdutos: [],
        campanhaPremiacaoRegraParametroConvenios: []
      });

      

      actions.setItem("campanhaPremiacaoRegras", arr);
      actions.cleanRegraProdutividade();
      actions.setItem("campanhaPremiacaoRegras", arr);
      actions.cleanRegraProdutividade();
    }
  }

  async function handleDeleteRegra(id, index) {
    if (id) {
      const isSuccess = await controller.deleteRegraProdutividade(id);
      if (isSuccess) {
        const arrString = JSON.stringify(item.campanhaPremiacaoRegras);
        const arr = JSON.parse(arrString);
        arr.splice(index, 1);
        actions.setItem("campanhaPremiacaoRegras", arr);
      }
    }
  }

  function handleOpenModal(regra, index) {
    if (!!regra.campanhaPremiacaoMetaProdutividade) {
      regra.campanhaPremiacaoMetaProdutividade.forEach((meta) => {
        const { descricao } = allHierarquias?.find(
          (hierarquia) => hierarquia.id === meta.hierarquia?.value
        ) || { desrcicao: "" };
        meta.hierarquia = { ...meta.hierarquia, label: descricao };
        meta.campanhaPremiacaoMetaSuperacao.forEach((superacao) => {
          const { descricao: medida } = medidasPremiacao.find(
            (medida) => medida.id === superacao.medidaPremiacaoSuperacaoId
          );
          superacao.medidaPremiacaoSuperacao = medida;
        });
        meta.campanhaPremiacaoMetaProdutividadeRegraRepasse.forEach(
          (regraRepasse) => {
            const { descricao: tipoComissao } = tiposComissao.find(
              (tipoComissao) => tipoComissao.id === regraRepasse.tiposComissaoId
            );
            regraRepasse.tipoComissao = tipoComissao;
          }
        );
      });

      actionsEvento.metaProdutividade(regra.campanhaPremiacaoMetaProdutividade);
    }
    setCurrentRegraProdutividadeIndex(index);
    setCurrentRegraProdutividade({ id: regra.id, name: regra.descricao });
    toggleModal();
  }

  return (
    <div>
      {!seeRegister && !item.hasAproved && (
        <div className="pr-5 pl-5 mt-3 mb-3">
          <Row>
            <Col md={4}>
              <Label>
                Descrição da Regra{" "}
                <span
                  style={{ color: "var(--color-laranja)", fontSize: "16px" }}
                >
                  *
                </span>
              </Label>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Digite aqui..."
                  value={regraProdutividade.descricao}
                  onChange={(e) =>
                    actions.setRegraProdutividade(
                      "descricao",
                      e.target.value.toUpperCase()
                    )
                  }
                  disabled={seeRegister}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <Switch
                className="switch"
                componentTop="Meta Global"
                checked={regraProdutividade.metaGlobal}
                onClick={() =>
                  actions.setRegraProdutividade(
                    "metaGlobal",
                    !regraProdutividade.metaGlobal
                  )
                }
                disabled={seeRegister}
              />
            </Col>
            <Col md={3}>
              <Switch
                className="switch"
                componentTop="Critério de Elegibilidade"
                checked={regraProdutividade.metaPrincipal}
                onClick={() =>
                  actions.setRegraProdutividade(
                    "metaPrincipal",
                    !regraProdutividade.metaPrincipal
                  )
                }
                disabled={seeRegister}
              />
            </Col>
            <Col md={1}>
              {!seeRegister && !item.usuarioAprovadorId && (
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    margin: "5px 0 0 0",
                    color: "var(--color-preto)",
                  }}
                  onClick={(e) => handleAddRegra()}
                  disabled={seeRegister}
                >
                  <AiOutlinePlusCircle
                    style={{
                      color: "var(--color-verde)",
                      marginRight: "5px",
                      fontSize: "20px",
                    }}
                  />
                </button>
              )}
            </Col>
          </Row>
        </div>
      )}
      <TabelaRegraProdutividade
        content={item.campanhaPremiacaoRegras}
        handleDeleteRegra={handleDeleteRegra}
        handleModalProdutividade={handleOpenModal}
      />

      <ModalRegraProdutividade
        isModalOpen={isModalOpen}
        toggle={toggleModal}
        addNewHierarquia={handleAddNewHierarquia}
        onChangeMetaProdutividade={onChangeMetaProdutividade}
        handleSaveMetaProdutividade={handleSaveMetaProdutividade}
        handleDeleteMetaProdutividade={handleDeleteMetaProdutividade}
        currentRegraProdutividade={currentRegraProdutividade}
        indexRegraProdutividade={currentRegraProdutividadeIndex}
      />
    </div>
  );
}

function TabelaRegraProdutividade({
  content,
  handleModalProdutividade,
  handleDeleteRegra,
}) {
  const { item, seeRegister } = useSelector((state) => state.campanhaPremiacao);
  const columns = useMemo(() => COLUMNS_REGRA_PRODUTIVIDADE, []);
  let data = content;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
  };

  function handleInitialPage() {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }
  const pageCount = Math.ceil(rows.length / pageSize);

  return (
    <div style={{ padding: "30px" }}>
      <div style={{ overflowX: "auto" }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    style={
                      column.id === "metaGlobal"
                        ? {
                            background: "#22c071",
                            color: "white",
                            height: "40px",
                            border: "1px solid #b9cbdb",
                            padding: "10px",
                            textAlign: "center",
                            minWidth: "150px",
                          }
                        : {
                            background: "#22c071",
                            color: "white",
                            height: "40px",
                            border: "1px solid #b9cbdb",
                            padding: "10px",
                            textAlign: "center",
                            minWidth: "250px",
                          }
                    }
                  >
                    {column.render("Header")}
                    <span className="float-right">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsIcons.BsArrowDown
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({ title: undefined })
                            )}
                          />
                        ) : (
                          <BsIcons.BsArrowUp
                            id="OrdenarAsc"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({ title: undefined })
                            )}
                          />
                        )
                      ) : (
                        <BsIcons.BsArrowDownUp
                          id="Ordenar"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th
                  style={{
                    background: "#22c071",
                    color: "white",
                    height: "40px",
                    border: "1px solid #b9cbdb",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  Ações
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={`tableRegraProdutividade${index}`}
                  style={{
                    height: "40px",
                    border: "1px solid #b9cbdb",
                    padding: "10px",
                    textAlign: "center",
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ textAlign: "center" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        <Tooltip title={"Modal de superação"}>
                          <MdAutoGraph
                            style={{
                              color: "var(--color-roxo)",
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              marginRight: "5px",
                            }}
                            onClick={() =>
                              handleModalProdutividade(row.original, index)
                            }
                          />
                        </Tooltip>
                        {!item.usuarioAprovadorId && !seeRegister && (
                          <BiIcons.BiTrash
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                            }}
                            onClick={() =>
                              handleDeleteRegra(row.original.id, index)
                            }
                            disabled={seeRegister}
                          />
                        )}
                      </>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Row className="pt-4">
        <Col>
          <span style={{ color: "var(--color-cinza-medio)" }}>
            Mostrando {page.length} de {content.length}
          </span>
        </Col>
        <Col md={8}>
          <Row className="float-right">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              initialPage={0}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={initialPage ? 0 : selectedPage}
            />
            <Input
              type="select"
              className="pageSize"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handleInitialPage();
              }}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Input>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Card,
  Button,
  Input,
  Collapse,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import * as actions from "../../../../../../store/modules/personas/papeis_store/actions";
import * as controllerPapeis from "../../../../../../controllers/personasController/papeisController";
import { Select, SelectPessoaLoja, Switch } from "../../../../../../components/Inputs/Input";
import SelectEdit from "../../../../../../components/Inputs/Select";
import "../../../../styles/papeis.css";
import TableLojaBanco from "./subComponents/TableLojaBanco";
import TablePersona from "./subComponents/TablePersona";
import history from "../../../../../../util/history";
import * as centroActions from "../../../../../../store/modules/vinculoCentroDeCusto/actions";
import * as personasController from "../../../../../../controllers/personasController/personasController";
import * as papeisActions from "../../../../../../store/modules/personas/papeis_store/actions";
import * as parceriaActions from "../../../../../../store/modules/tipoParceria/actions";
import { maskDate, maskMoeda, removeMaskCPF } from "../../../../../../util/masks";
import "../../../../../../style.css";
import { notifyWarn } from "../../../../../../components/message/message";
import ModalFuncionarios from "./subComponents/ModalFuncionarios";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import moment from "moment";
import { InputCpfCnpj } from "../../../../../../components/Inputs/Input";
import { INITIAL_STATE } from "../../../../../../store/modules/personas/papeis_store";
import { Checkbox } from "@material-ui/core";

const Matriz = () => {
  const {
    tipoLoja,
    matrizes,
    tiposLoja,
    canais,
    centrosDeCusto,
    seeRegister,
    lojasBancosSelected,
    personasSelected,
    lojaBanco,
    masters,
    instituicoesFinanceira,
    tiposParcerias,
    pessoa,
    filterPessoa,
    filterPessoaApplied,
    niveisHierarquicos,
    usuariosBanco,
    usuariosFuncionarios,
  } = useSelector((state) => state.personasPapeis);
  const { informacao } = useSelector((state) => state.personasInformacoes);

  const [isLojaOpen, setIsLojaOpen] = useState(false)
  const [isLojaBancoOpen, setIsLojaBancoOpen] = useState(false)
  const [isLojaPessoaOpen, setIsLojaPessoaOpen] = useState(false)

  const [modalFuncionario, setModalFuncionario] = useState(false)
  const toggleFuncionario = () => setModalFuncionario(!modalFuncionario)

  async function populateSelect() {
    await controllerPapeis.getTiposLoja();
    await controllerPapeis.getCanais(false);
    tipoLoja.id && (await controllerPapeis.getCentrosCusto(tipoLoja.id));
    await controllerPapeis.getParcerias();
    await controllerPapeis.getInstituicoes();
  }

  useEffect(() => {
    populateSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(tipoLoja.canalId !== '0'){
      controllerPapeis.getUsuarioFuncionario(tipoLoja.canalId);
      controllerPapeis.getHierarquia(tipoLoja.canalId);
      controllerPapeis.getMatrizes(tipoLoja.canalId);
    }else{
      papeisActions.usuarioFuncionario([])
      papeisActions.niveisHierarquicos([])
      papeisActions.setMatrizes([])
    }
  }, [tipoLoja.canalId]);

  useEffect(() => {
    if(lojaBanco.id === '0' && lojaBanco.bancoId !== '0'){
      controllerPapeis.getMasters(lojaBanco.bancoId);
    }else{
      papeisActions.setMasters([])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lojaBanco.bancoId]);

  useEffect(() => {
    if(pessoa.pessoaId !== '0'){
      controllerPapeis.getUsuarioBanco(tipoLoja.id, pessoa.pessoaId)
    }
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoa.pessoaId]);

  useEffect(() => {
    if(lojaBanco.id && lojaBanco.id !== '0'){
      controllerPapeis.getMasters(lojaBanco.bancoId)
    }
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lojaBanco.id]);

  function onChange(value, field) {
    actions.tipoLoja({ ...tipoLoja, [field]: value });
  }

  async function salveLojaBanco() {
    let isValid = true
    if(lojaBanco.tipoParceria !== 'MASTER' && lojaBanco.lojaMasterId === '0'){
      isValid = false
      notifyWarn("É necessário informar uma master para esse tipo de parceria")
    }
    lojasBancosSelected.forEach((loja) => {
      if(lojaBanco.id === '0' && loja.codigoLojaBanco === lojaBanco.codigoLojaBanco.trim() && loja.bancoId === lojaBanco.bancoId){
        isValid = false
        notifyWarn("Código de loja da instituição já cadastrado para essa instituição")
      }
    })
    if(isValid){
      await personasController.salveLojaBanco(
        informacao.id,
        tipoLoja.id,
        lojaBanco
      );
    }
  }

  function onChangePersonas(value, field) {
    papeisActions.setPessoa(field, value);
  }

  function onChangeFilterPersonas(value, field) {
    papeisActions.setFilterPessoa(field, value);
  }

  function onApplyFilterPersonas() {
    papeisActions.setFilterPessoaApplied(filterPessoa);
  }

  function changeViewTipoParceria() {
    history.push("/tipodeparceria");
    parceriaActions.setActivePersona(true);
    parceriaActions.setActiveTab("Register");
  }
  async function salvePersona() {
    var isSuccess = await personasController.salvePersonas(tipoLoja.id, pessoa);
    if (isSuccess){
      personasSelected.push(pessoa)
    }
    papeisActions.cleanPersona();
  }


  function onChangeLoja(value, field) {
    papeisActions.setLojaBanco( field ,value );
  }

  function onChangeCodigoLojaInstituicao(value) {
    papeisActions.setLojaBanco("codigoLojaBanco", value.replace(/^0+/, ''));
  }

  function changeView() {
    history.push("/centrodecusto");
    centroActions.setActivePersona(true);
    centroActions.setActiveTab("Register");
  }

  useEffect(() => {
    if (!tipoLoja.isMatriz) {
      onChange('', "ir")
    } else {
      onChange('0', "matrizId")
      controllerPapeis.getMatrizes(tipoLoja.canalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoLoja.isMatriz])

  const getInstituicoesFinanceirasOptions = () => {
    const options = []

    lojasBancosSelected?.forEach(loja => {
      const option = instituicoesFinanceira?.find(e=> e.id === loja.bancoId)

      if(option) options.push(option)
    })

    return options
  }

  const getUsuariosBancoOptions = () => {
    const options = []

      personasSelected.forEach(persona => {
        persona.body.forEach(row => {
          if(!row.usuarioBanco || !row.usuarioBanco.usuarioInstituicao) return
            const optionAlreadyExist = options.some(option => option.label === row.usuarioBanco.usuarioInstituicao)

            if(!optionAlreadyExist) {
              options.push({
                id: row.usuarioBanco.usuarioInstituicao,
                label: row.usuarioBanco.usuarioInstituicao
              })
            }
        })
      })

    return options
  }

  const getIsFiltering = () => {
    const originalValuesKeys = Object.keys(INITIAL_STATE.filterPessoaApplied);

    for (const key of originalValuesKeys) {
      if (INITIAL_STATE.filterPessoaApplied[key] !== filterPessoaApplied[key]) {
        return true;
      }
    }

    return false;
  }

  function getStatusLojaPessoaPorHierarquia(pessoaBody) {
    if(pessoaBody.some(d => !d.dataVigenciaFinal)) {
      return true
    }

    pessoaBody.some(d => {
      const actualDate = new Date()
      const targetDate = new Date(d.dataVigenciaFinal);

      return targetDate > actualDate ? true : false
    })

    return false
  }

  const getDataFiltered = () => {
    const nameFilterRegExp = new RegExp(`\\b${filterPessoaApplied.nome}\\b`, 'i');

    const getNameFuncionario = (pessoaId) => {
      return usuariosFuncionarios.find(e => e.id === pessoaId).nome
    }

    const getCpfCnpjFuncionario = (pessoaId) => {
      return usuariosFuncionarios.find(e => e.id === pessoaId).cpfCnpj
    }
    
    return personasSelected
    .filter(persona => {
      if (filterPessoaApplied.hierarquiaId !== '0') {
          return persona.header.hierarquiaId === filterPessoaApplied.hierarquiaId;
        }
        return true;
      })
      .filter(persona => {
        if (filterPessoaApplied.status !== '0') {
          return getStatusLojaPessoaPorHierarquia(persona.body) === filterPessoaApplied.status;
        }
        return true;
      })
      .map(persona => {
        
        
        return {
          ...persona,
          body: persona.body.filter((b) => {
          return (filterPessoaApplied.cpf === '' || getCpfCnpjFuncionario(b.pessoaId ?? '') === removeMaskCPF(filterPessoaApplied.cpf)) &&
            (filterPessoaApplied.nome === '' || nameFilterRegExp.test(getNameFuncionario(b.pessoaId ?? ''))) &&
            (filterPessoaApplied.instituicaoId === '0' || (b.usuarioBanco?.banco?.id ?? '') === filterPessoaApplied.instituicaoId) &&
            (filterPessoaApplied.usuarioInstituicaoId === '0' || (b.usuarioBanco?.usuarioInstituicao ?? '') === filterPessoaApplied.usuarioInstituicaoId)
          }),
          };
        })
        .filter(persona => persona.body.length !== 0);
      };

      function clearFilters() {
        papeisActions.clearFilterPessoa()
        papeisActions.clearFilterPessoaApplied()
      }


  const data = getIsFiltering() ? getDataFiltered() : personasSelected

  return (
    <>
      <div style={{ marginTop: "25px" }}>
        <Col md={12}>
          <Form>
          {
            <div style={{marginBottom: '10px'}}>
            <div style={{display: 'flex', marginTop: '30px' ,paddingRight: '20px'}} >
                  <p style={{fontWeight: 'bold', color: 'var(--color-roxo)', margin: '5px auto 0 0'}}>
                    {`Loja`}
                </p>
                {isLojaOpen ?
                    <RiArrowUpSLine
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsLojaOpen(!isLojaOpen)}/>
                :
                    <RiArrowDownSLine
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsLojaOpen(!isLojaOpen)}/>}
            </div>
            <Collapse isOpen={isLojaOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
          <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Tipo de loja</Label>
                  <Select
                    first="Selecione"
                    value={{ value: tipoLoja.tipoLojaId }}
                    select={{ value: "id", label: "nome" }}
                    options={tiposLoja}
                    onChange={(e) => onChange(e.value, "tipoLojaId")}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={seeRegister}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Canal</Label>
                  <Select
                    first="Selecione"
                    value={{ value: tipoLoja.canalId }}
                    select={{ value: "id", label: "nome" }}
                    options={canais}
                    onChange={(e) => onChange(e.value, "canalId")}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={tipoLoja.id ? true : seeRegister}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                    <Switch
                      className="switch"
                      checked={tipoLoja.isMatriz}
                      onChange={(e)=> {onChange(!tipoLoja.isMatriz, "isMatriz")}}
                      componentTop= 'Matriz'
                    />
              </Col>
              {!tipoLoja.isMatriz ?
                <Col md={4}>
                  <FormGroup>
                    <Label>Matriz</Label>
                    <Select
                      first="Selecione"
                      value={{ value: tipoLoja.matrizId }}
                      select={{ value: "id", label: "nome" }}
                      options={matrizes}
                      onChange={(e) => onChange(e.value, "matrizId")}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={ seeRegister}
                    />
                  </FormGroup>
                </Col>:
                <Col md={3}>
                <FormGroup>
                  <Label>IR</Label>
                  <Input
                  type='text'
                  maxLength={6}
                    value={maskMoeda(tipoLoja.ir)}
                    disabled={seeRegister}
                    onChange={(e) =>
                      onChange(maskMoeda(e.target.value).replaceAll(',', '.') > 100 ? "100,00" : maskMoeda(e.target.value), "ir")
                    }
                  />
                </FormGroup>
              </Col>}
              <Col md={3}>
                <FormGroup>
                  <Label>Código da Empresa Telecom</Label>
                  <Input
                  type='text'
                    value={tipoLoja.empresa}
                    placeholder="Digite aqui..."
                    disabled={seeRegister}
                    onChange={(e) =>
                      onChange(e.target.value, "empresa")
                    }
                  />
                </FormGroup>
              </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Id código empresa W3ERP</Label>
                <Input
                    type='text'
                    value={tipoLoja.cdEmpresaW3ERP}
                    placeholder="Digite aqui..."
                    disabled={seeRegister}
                    onChange={(e) =>
                        onChange(e.target.value, "cdEmpresaW3ERP")
                    }
                />
              </FormGroup>
            </Col>
            <Col md={3}>
                <Label> Exibe no Simulador </Label> <br/>
                <Checkbox
                    checked={tipoLoja.exibeSimulador}
                    style={{color: 'green'}}
                    onClick={()=> onChange(!tipoLoja.exibeSimulador, 'exibeSimulador')}
                />
            </Col>
            </Row>
          </Collapse>
            </div>
            }

            <br />
            <br />
            {
              <div style={{borderTop: '1px solid #CCCFDE', marginBottom: '10px'}}>
              <div style={{display: 'flex', marginTop: '30px' ,paddingRight: '20px'}} >
                  <p style={{fontWeight: 'bold', color: 'var(--color-roxo)', margin: '5px auto 0 0'}}>
                      {`Loja Banco`}
                  </p>
                  {isLojaBancoOpen ?
                      <RiArrowUpSLine
                      style={{fontSize: '25px', cursor: 'pointer'}}
                      onClick={()=> setIsLojaBancoOpen(!isLojaBancoOpen)}/>
                  :
                      <RiArrowDownSLine
                      style={{fontSize: '25px', cursor: 'pointer'}}
                      onClick={()=> setIsLojaBancoOpen(!isLojaBancoOpen)}/>}
              </div>
              <Collapse isOpen={isLojaBancoOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
            <Card
              className="p-3 "
              style={{ backgroundColor: "var(--color-quase-branco)" }}
            >
              <Row className="margin-row">
                <Col md={4}>
                  <Label>Instituição Financeira</Label>
                  {
                    !lojaBanco.isEditable ? (
                      <Select
                      first="Selecione"
                      value={{ value: lojaBanco.bancoId }}
                      select={{ value: "id", label: "nome" }}
                      options={instituicoesFinanceira}
                      onChange={(e) => { onChangeLoja(e.value, "bancoId"); onChangeLoja('0', "lojaMasterId")}}
                      isSearchable={true}
                      autoComplete="off"
                    disabled={seeRegister? true : lojaBanco.isEditable}
                  />
                    )
                    :
                    (
                      <SelectEdit
                      value={ lojaBanco.bancoId }
                      label= 'nome'
                      options={instituicoesFinanceira}
                      disabled={true}

                  />
                    )
                  }
                </Col>
                <Col md={4}>
                  <Label>Relacionamento</Label>
                  <Select
                    first="Selecione"
                    value={{ value: lojaBanco.tipoParceriaId }}
                    select={{ value: "id", label: "nome" }}
                    options={tiposParcerias}
                    onChange={(e) => {onChangeLoja(e.label, "tipoParceria"); onChangeLoja(e.value, "tipoParceriaId"); e.label === 'MASTER' && onChangeLoja('0', 'lojaMasterId'); e.label === 'AGENTE' && onChangeLoja('0', 'codigoLojaBanco');}}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={seeRegister? true : lojaBanco.isEditable}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    style={{
                      marginTop: "25px",
                      width: "186px",
                      background: "var(--color-branco)",
                      borderColor: "var(--color-verde)",
                      color: "var(--color-verde)",
                      borderRadius: "7px",
                    }}
                    disabled={seeRegister ? true : lojaBanco.isEditable}
                    onClick={() => changeViewTipoParceria()}
                  >
                    Novo Tipo de Parceria
                  </Button>
                </Col>
              </Row>

              <Row className="margin-row">
                <Col md={4}>
                  <FormGroup>
                    <Label>Código de loja da instituição</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui..."
                      value={lojaBanco.codigoLojaBanco}
                      onChange={(e) =>
                        onChangeCodigoLojaInstituicao(e.target.value)
                      }
                      disabled={lojaBanco.possuiComissao ? true : seeRegister? true : lojaBanco.isDataFinalEditable ? true : (lojaBanco.isEditable && lojaBanco.divisaoComissao) ? true : (lojaBanco.tipoParceria === "AGENTE" && true)}
                      maxLength="150"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Loja master</Label>
                    {
                    !lojaBanco.id ? (
                      <Select
                      first="Selecione"
                      value={{ value: lojaBanco.lojaMasterId }}
                      select={{ value: "id", label: "nome" }}
                      options={masters}
                      onChange={(e) => { onChangeLoja(e.value, "lojaMasterId")}}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister ? true : lojaBanco.tipoParceria === 'MASTER' ? true : false}
                  />
                    )
                    :
                    (
                      <SelectEdit
                      value={ lojaBanco.lojaMasterId }
                      label= 'descricao'
                      options={masters}
                      onChange={(e) => { onChangeLoja(e.value, "lojaMasterId")}}
                      disabled={seeRegister ? true : lojaBanco.tipoParceria === 'MASTER' ? true : false}/>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="margin-row">
                <Col md={4}>
                  <FormGroup>
                    <Label>Data de início da vigência</Label>
                    <Input
                      type="date"
                      max="9999-12-31"
                      value={lojaBanco.dataVigenciaInicial}
                      disabled={seeRegister? true : lojaBanco.isEditable}
                      onChange={(e) =>
                        onChangeLoja(e.target.value, "dataVigenciaInicial")
                      }
                      onBlur={(e)=> {
                        if(lojaBanco.dataVigenciaInicial.trim() !== '' && !moment(lojaBanco.dataVigenciaInicial).isValid()){
                          onChangeLoja(' ', "dataVigenciaInicial")
                          notifyWarn('A data informada é invalida')
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Data de fim da vigência</Label>
                    <Input
                      type="date"
                      max="9999-12-31"
                      value={lojaBanco.dataVigenciaFinal}
                      onChange={(e) =>
                        onChangeLoja(e.target.value, "dataVigenciaFinal")
                      }
                      onBlur={(e)=> {
                        if(lojaBanco.dataVigenciaFinal.trim() !== '' && !moment(lojaBanco.dataVigenciaFinal).isValid()){
                          onChangeLoja(' ', "dataVigenciaFinal")
                          notifyWarn('A data informada é invalida')
                        }
                      }}
                      disabled={seeRegister? true : lojaBanco.isDataFinalEditable}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {!seeRegister && (
                <Label
                  className="mt-2"
                  onClick={() => salveLojaBanco()}
                  style={{ cursor: "pointer" }}
                >
                  <AiIcons.AiOutlinePlusCircle
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "7px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  />
                  Adicionar Instituição
                </Label>
              )}
            </Card>
            {lojasBancosSelected.length > 0 &&
             <TableLojaBanco lojasBancos={lojasBancosSelected}/>}
              </Collapse>
              </div>
              }
            <br />
            <br />
            {
              <div style={{borderTop: '1px solid #CCCFDE', marginBottom: '10px'}}>
              <div style={{display: 'flex', marginTop: '30px' ,paddingRight: '20px'}} >
                  <p style={{fontWeight: 'bold', color: 'var(--color-roxo)', margin: '5px auto 0 0'}}>
                      {`Loja Pessoa`}
                  </p>
                  {isLojaPessoaOpen ?
                      <RiArrowUpSLine
                      style={{fontSize: '25px', cursor: 'pointer'}}
                      onClick={()=> setIsLojaPessoaOpen(!isLojaPessoaOpen)}/>
                  :
                      <RiArrowDownSLine
                      style={{fontSize: '25px', cursor: 'pointer'}}
                      onClick={()=> setIsLojaPessoaOpen(!isLojaPessoaOpen)}/>}
              </div>
              <Collapse isOpen={isLojaPessoaOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
            <Card
              className="p-3 "
              style={{ backgroundColor: "var(--color-quase-branco)" }}
            >
              <p
                style={{
                  margin: "15px 0 0 20px",
                  fontWeight: "700",
                  color: "var(--color-preto)",
                }}
              >
                Defina as personas que atuam nessa Loja/Parceiro
              </p>
              <Row className="margin-row">
                <Col>
                  <Label>Nível Hierárquico</Label>
                  <Select
                    first="Selecione"
                    value={{ value: pessoa.hierarquiaId }}
                    select={{ value: "id", label: "descricao" }}
                    options={niveisHierarquicos}
                    onChange={(e) => onChangePersonas(e.value, "hierarquiaId")}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={seeRegister ? true :(tipoLoja.dataFimVigencia && tipoLoja.dataFimVigencia !== '') ? true : pessoa.isEditable}
                  />
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Funcionário</Label>
                        <Input
                        type="text"
                        placeholder="Busque um funcionário..."
                        value={pessoa.pessoa}
                        disabled
                        />
                    </FormGroup>
                </Col>
                <AiIcons.AiOutlineSearch
                    style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                    onClick={()=> !pessoa.isEditable ? toggleFuncionario() : null}
                />
              </Row>
              <Row className="margin-row">
                <Col md={4}>
                  <Label>Usuário banco</Label>
                  {
                    !pessoa.isEditable ?
                      <SelectPessoaLoja
                      first="Selecione"
                      value={{ value: pessoa.usuarioBancoId }}
                      select={{ value: "id", label: "usuarioInstituicao", dataInicio: "dataVigenciaInicial", dataFim: "dataVigenciaFinal" }}
                      options={usuariosBanco}
                      onChange={(e) => {onChangePersonas(e.value, "usuarioBancoId");  onChangePersonas(maskDate(e.dataInicio, 'YYYY-MM-DD'), "dataVigenciaInicial");  onChangePersonas(maskDate(e.dataFim, 'YYYY-MM-DD'), "dataVigenciaFinal"); e.dataFim ? onChangePersonas(true, "isDataFinalEditable") : onChangePersonas(false, "isDataFinalEditable")}}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister ? true : (tipoLoja.dataFimVigencia && tipoLoja.dataFimVigencia !== '') ? true : false}
                    />
                    :
                    <Input
                    type="text"
                    value={pessoa.usuarioBanco}
                    disabled={true}
                  />
                  }
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Centro de custo</Label>
                    <Select
                      first="Selecione"
                      value={{ value: pessoa.centroCustoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={centrosDeCusto}
                      onChange={(e) => {onChangePersonas(e.value, "centroCustoId"); onChangePersonas(e.label, "centroCusto")}}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={!!seeRegister}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <Button
                    style={{
                      marginTop: "25px",
                      width: "186px",
                      background: "var(--color-branco)",
                      borderColor: "var(--color-verde)",
                      color: "var(--color-verde)",
                      borderRadius: "7px",
                    }}
                    disabled={seeRegister ? true : (tipoLoja.dataFimVigencia && tipoLoja.dataFimVigencia !== '') ? true : false}
                    onClick={() => changeView()}
                  >
                    Novo Centro de Custo
                  </Button>
                </Col>
              </Row>
              <Row className="margin-row">
                <Col md={4}>
                  <FormGroup>
                    <Label>Data de início da vigência</Label>
                    <Input
                      type="date"
                      max="9999-12-31"
                      value={pessoa.dataVigenciaInicial}
                      disabled={pessoa.usuarioBancoId !== '0' ? true : seeRegister ? true : (tipoLoja.dataFimVigencia && tipoLoja.dataFimVigencia !== '') ? true : pessoa.isEditable}
                      onChange={(e) =>
                        onChangePersonas(e.target.value, "dataVigenciaInicial")
                      }
                      onBlur={(e)=> {
                        if(pessoa.dataVigenciaInicial.trim() !== '' && !moment(pessoa.dataVigenciaInicial).isValid()){
                          onChangeLoja(' ', "dataVigenciaInicial")
                          notifyWarn('A data informada é invalida')
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Data de fim da vigência</Label>
                    <Input
                      type="date"
                      max="9999-12-31"
                      value={pessoa.dataVigenciaFinal}
                      onChange={(e) =>
                        onChangePersonas(e.target.value, "dataVigenciaFinal")
                      }
                      onBlur={(e)=> {
                        if(pessoa.dataVigenciaFinal.trim() !== '' && !moment(pessoa.dataVigenciaFinal).isValid()){
                          onChangeLoja(' ', "dataVigenciaFinal")
                          notifyWarn('A data informada é invalida')
                        }
                      }}
                      disabled={seeRegister ? true : ( tipoLoja.dataFimVigencia && tipoLoja.dataFimVigencia !== '') ? true : pessoa.isDataFinalEditable}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Switch
                      componentTop={"Headcount"}
                      className={"switch switch-headcount"}
                      checked={pessoa.headCount}
                      onChange={(e) =>
                        onChangePersonas(!pessoa.headCount, "headCount")
                      }
                      disabled={seeRegister}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!seeRegister && (
                <Label
                  className="mt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {salvePersona()}}
                >
                  <AiIcons.AiOutlinePlusCircle
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "7px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  />
                  Adicionar Persona
                </Label>
              )}
            </Card>


            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 0 20px 0', padding: '20px'}}>
              <p style={{fontSize: '16px', fontWeight: '700'}}>Filtros</p>
              <Row className="margin-row">
                <Col md={4}>
                    <FormGroup>
                    <Label className="float-left" for="cpf">CPF</Label>
                      <InputCpfCnpj
                        type="text"
                        placeholder="Digite aqui..."
                        tipo="cpf"
                        value={filterPessoa.cpf}
                        onChange={e => onChangeFilterPersonas(e.target.value, "cpf")}
                        maxLength="18"
                      />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                    <Label className="float-left" for="cpf">Nome</Label>
                      <Input
                        type="text"
                        placeholder="Digite aqui..."
                        value={filterPessoa.nome}
                        onChange={e => onChangeFilterPersonas(e.target.value, "nome")}
                      />
                    </FormGroup>
                </Col>
                <Col>
                  <Label>Nível Hierárquico</Label>
                  <Select
                    first="Selecione"
                    value={{ value: filterPessoa.hierarquiaId }}
                    select={{ value: "id", label: "descricao" }}
                    options={niveisHierarquicos}
                    onChange={(e) => onChangeFilterPersonas(e.value, "hierarquiaId")}
                    isSearchable={true}
                    autoComplete="off"
                  />
                </Col>
              </Row>

              <Row className="margin-row" style={{marginBottom: "3rem"}}>
                <Col md={4}>
                  <Label>Instituição</Label>
                      <SelectPessoaLoja
                      first="Selecione"
                      value={{ value: filterPessoa.instituicaoId }}
                      select={{ value: "id", label: "nome"}}
                      options={getInstituicoesFinanceirasOptions()}
                      onChange={(e) => {onChangeFilterPersonas(e.value, "instituicaoId")}}
                      isSearchable={true}
                      autoComplete="off"
                    />
                </Col>
                <Col md={4}>
                  <Label>Usuário Instituição</Label>
                      <SelectPessoaLoja
                      first="Selecione"
                      value={{ value: filterPessoa.usuarioInstituicaoId }}
                      select={{ value: "id", label: "label"}}
                      options={getUsuariosBancoOptions()}
                      onChange={(e) => {onChangeFilterPersonas(e.value, "usuarioInstituicaoId")}}
                      isSearchable={true}
                      autoComplete="off"
                    />
                </Col>
                <Col md={4}>
                  <Label>Status</Label>
                      <SelectPessoaLoja
                      first="Selecione"
                      value={{ value: filterPessoa.status }}
                      select={{ value: "id", label: "label"}}
                      options={[{ id: true, label: "Ativo" }, { id: false, label: "Inativo" }]}
                      onChange={(e) => {onChangeFilterPersonas(e.value, "status")}}
                      isSearchable={true}
                      autoComplete="off"
                    />
                </Col>
              </Row>
              <div style={{ paddingTop: '20px'}}>
                    <Row>
                        <Col>
                        <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => onApplyFilterPersonas()}>
                                  Aplicar
                          </Button>
                          <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => clearFilters()}>
                                  Limpar Filtros
                            </Button>
                        </Col>
                    </Row>
                </div>
              </div>

            {personasSelected.length > 0 &&
            <div style={{width: '100%'}}>
              <TablePersona pessoas={data}/>
            </div>
                }
              </Collapse>
              </div>}

            <br />
            <br />
          </Form>
        </Col>
      </div>
      {
            <ModalFuncionarios
                isOpen={modalFuncionario}
                toggle={toggleFuncionario} />
        }
    </>
  );
};

export default Matriz;

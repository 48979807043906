import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  item: {
    canalId: "0",
    periodoId: "0",
    dataInicio: "",
    dataFim: "",
    campanhaId: "0",
    pessoaId: "0",
    pessoa: "",
    hierarquiaId: "0",
    centroCustoId: "0",
    empresaFuncionarioId: "0",
    campanhaTipo: "0",
    statusIntegracao: "0",
    statusFavorecido: "0",
    sistema: "0",
    dataPagamento: "",
    dataIntegracao: "",
    aceito: false,
    sistemaId: "0",
  },
  modalConsultores: {
    id: "",
    nome: "",
    cpf: "",
    hierarquiaId: "0",
    canalId: "0",
  },
  getAllConsultores: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  allItems: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  canais: [],
  campanhas: [],
  hierarquias: [],
  pessoas: [],
  consultores: [],
  centrosCusto: [],
  empresasFuncionario: [],
  campanhaTipo: [
    { id: "1", descricao: "Produtividade " },
    { id: "2", descricao: "Superacao  " },
    { id: "3", descricao: "RegraRepasse  " },
  ],
  statusIntegracao: [
    {
      id: "6b48e5a6-f46e-4388-8940-60d9a6629c1c",
      descricao: "PENDENTE",
    },
    {
      id: "4d11f8b0-bb9a-44e6-bbda-37591c130435",
      descricao: "APROVADO",
    },
    {
      id: "ceca981e-9068-47c5-8735-c9677d2d12f2",
      descricao: "ESTORNADO",
    },
    {
      id: "5fd35513-c165-40ec-be20-fb1dcf2fc671",
      descricao: "EM ABERTO",
    },
  ],
  statusFavorecido: [
    {
      id: true,
      descricao: "Admitido",
    },
    {
      id: false,
      descricao: "Demitido",
    },
  ],
  sistema: [],
  items: [],
  totals: {},
  activeTab: "Search",
  seeRegister: false,
  enableCheckboxesRule: "",
  modalEdicao: {
    sistemaId: "0",
    tipoRegraId: "0",
    valor: 0,
  },
  selectedItems: [],
  permitions: {
    criar: false,
    deletar: false,
  },
};

export default function contasReceber(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_MODAL_CONSULTORES:
          return {
            ...state,
            modalConsultores: {
              ...state.modalConsultores,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_GET_ALL_CONSULTORES:
          return { ...state, getAllConsultores: action.value };
        case TYPES.GET_ALL_ITENS:
          return { ...state, allItems: action.value };
        case TYPES.ITEM:
          return {
            ...state,
            item: { ...state.item, [action.field]: action.value },
          };
        case TYPES.ITEMS:
          return { ...state, items: action.value };
        case TYPES.TOTALS:
          return { ...state, totals: action.value };
        case TYPES.SET_CANAIS:
          return { ...state, canais: action.value };
        case TYPES.SET_CAMPANHA:
          return { ...state, campanhas: action.value };
        case TYPES.SET_HIERARQUIAS:
          return { ...state, hierarquias: action.value };
        case TYPES.SET_CONSULTORES:
          return { ...state, consultores: action.value };
        case TYPES.SET_CENTROS_CUSTO:
          return { ...state, centrosCusto: action.value };
        case TYPES.SET_EMPRESAS_FUNCIONARIO:
          return { ...state, empresasFuncionario: action.value };
        case TYPES.SET_CAMPANHA_TIPO:
          return { ...state, campanhaTipo: action.value };
        case TYPES.SET_STATUS_INTEGRACAO:
          return { ...state, statusIntegracao: action.value };
        case TYPES.STATUS_FAVORECIDO:
          return { ...state, statusFavorecido: action.value };
        case TYPES.SET_SISTEMAS:
          return { ...state, sistemas: action.value };
        case TYPES.CLEAN_SEARCH:
          return {
            ...state,
            item: INITIAL_STATE.item,
          };
        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            item: INITIAL_STATE.item,
          };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.SET_SEE:
          return { ...state, seeRegister: action.value };
        case TYPES.ENABLE_CHECKBOXES:
          return { ...state, enableCheckboxesRule: action.value };
        case TYPES.CLEAN_MODAL_EDICAO:
          return { ...state, modalEdicao: INITIAL_STATE.modalEdicao };
        case TYPES.SET_MODAL_EDICAO:
          return { ...state, modalEdicao: action.value };
        case TYPES.SET_MODAL_EDICAO_FIELD:
          return {
            ...state,
            modalEdicao: {
              ...state.modalEdicao,
              [action.field]: action.value,
            },
          };
        case TYPES.SET_SELECTED_ITEMS:
          return { ...state, selectedItems: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}

import React, { useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/pt-br";
import {
  DatePickerContainer,
  HeaderContainer,
  YearSelector,
  MonthSelector,
  CustomButton,
  ButtonGroup,
  ButtonArrow,
  ButtonClear,
} from "./style";
import classNames from "classnames";
import { Input } from "reactstrap";

moment.locale("pt-br");

const CustomDatePicker = ({
  onChangeFiltros,
  startDate,
  endDate,
  accessorStart = "dataInicio",
  accessorEnd = "dataFim",
}) => {
  const [startDateState, setStartDateState] = useState(
    startDate ? moment(startDate).toDate() : null
  );
  const [endDateState, setEndDateState] = useState(
    endDate ? moment(endDate).toDate() : null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [datePickerKey, setDatePickerKey] = useState(Date.now());
  const [disabledPrevButton, setDisabledPrevButton] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(false);

  const years = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, i) => 1900 + i
  );

  const months = moment.months();

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDateState(start);
    setEndDateState(end);
    if (start && end) {
      onChangeFiltros(
        moment(start).isValid() ? moment(start).format("YYYY-MM-DD") : "",
        accessorStart
      );
      onChangeFiltros(
        moment(end).isValid() ? moment(end).format("YYYY-MM-DD") : "",
        accessorEnd
      );
    }
  };

  const handleClear = () => {
    setStartDateState(null);
    setEndDateState(null);
    setSelectedMonth(moment().month());
    setSelectedYear(moment().year());
    onChangeFiltros("", accessorStart);
    onChangeFiltros("", accessorEnd);
    setDatePickerKey(Date.now());
  };

  const handlePeriodoPreDefinido = (offset) => {
    const targetMonth = moment().subtract(offset, "months");
    const inicio = targetMonth.startOf("month").toDate();
    let fim;
    if (offset === 0) {
      fim = new Date();
    } else {
      fim = targetMonth.endOf("month").toDate();
    }

    setStartDateState(inicio);
    setEndDateState(fim);
    setSelectedMonth(targetMonth.month());
    setSelectedYear(targetMonth.year());
    onChangeFiltros(
      moment(inicio).isValid() ? moment(inicio).format("YYYY-MM-DD") : "",
      accessorStart
    );
    onChangeFiltros(
      moment(fim).isValid() ? moment(fim).format("YYYY-MM-DD") : "",
      accessorEnd
    );
    setDatePickerKey(Date.now());
  };

  const handlePreviousMonth = (date, changeMonth, changeYear) => {
    let newDate = moment(date).subtract(1, "month");
    changeMonth(newDate.month());
    changeYear(newDate.year());
    setSelectedMonth(newDate.month());
    setSelectedYear(newDate.year());
  };

  const handleNextMonth = (date, changeMonth, changeYear) => {
    let newDate = moment(date).add(1, "month");
    changeMonth(newDate.month());
    changeYear(newDate.year());
    setSelectedMonth(newDate.month());
    setSelectedYear(newDate.year());
  };

  useEffect(() => {
    if (selectedYear === 1900 && selectedMonth === 0) {
      setDisabledPrevButton(true);
    } else {
      setDisabledPrevButton(false);
    }

    if (selectedYear === moment().year() && selectedMonth === 11) {
      setDisabledNextButton(true);
    } else {
      setDisabledNextButton(false);
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    setStartDateState(startDate ? moment(startDate).toDate() : null);
  }, [startDate]);

  useEffect(() => {
    setEndDateState(endDate ? moment(endDate).toDate() : null);
  }, [endDate]);

  return (
    <DatePickerContainer isOpen={isOpen}>
      <DatePicker
        key={datePickerKey}
        open={isOpen}
        selected={startDateState}
        startDate={startDateState}
        endDate={endDateState}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/aaaa - dd/mm/aaaa"
        selectsRange
        onClickOutside={() => setIsOpen(false)}
        shouldCloseOnSelect={false}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HeaderContainer>
            <ButtonArrow
              onClick={() => handlePreviousMonth(date, changeMonth, changeYear)}
              disabled={prevMonthButtonDisabled || disabledPrevButton}
            >
              {"<"}
            </ButtonArrow>
            <YearSelector
              value={selectedYear}
              onChange={({ target: { value } }) => {
                const year = parseInt(value);
                setSelectedYear(year);
                changeYear(year);
              }}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </YearSelector>
            <MonthSelector
              value={selectedMonth}
              onChange={({ target: { value } }) => {
                const month = parseInt(value);
                const year = selectedYear;
                const isCurrentMonth =
                  year === moment().year() && month === moment().month();

                const startOfMonth = moment()
                  .year(year)
                  .month(month)
                  .startOf("month")
                  .toDate();

                const endOfMonth = isCurrentMonth
                  ? new Date()
                  : moment().year(year).month(month).endOf("month").toDate();

                setStartDateState(startOfMonth);
                setEndDateState(endOfMonth);
                setSelectedMonth(month);

                onChangeFiltros(
                  moment(startOfMonth).format("YYYY-MM-DD"),
                  accessorStart
                );
                onChangeFiltros(
                  moment(endOfMonth).format("YYYY-MM-DD"),
                  accessorEnd
                );

                changeMonth(month);
              }}
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month.charAt(0).toUpperCase() + month.slice(1)}
                </option>
              ))}
            </MonthSelector>

            <ButtonArrow
              onClick={() => handleNextMonth(date, changeMonth, changeYear)}
              disabled={nextMonthButtonDisabled || disabledNextButton}
            >
              {">"}
            </ButtonArrow>
          </HeaderContainer>
        )}
        onInputClick={() => {
          setIsOpen(!isOpen);
        }}
        customInput={<Input style={{ width: "100%", height: "35px" }} />}
        calendarContainer={({ className, children }) => (
          <CalendarContainer
            className={classNames(className, "d-flex flex-row-reverse")}
          >
            <div style={{ position: "relative" }}>{children}</div>
            <ButtonGroup>
              <CustomButton onClick={() => handlePeriodoPreDefinido(0)}>
                {months[moment().month()].charAt(0).toUpperCase() +
                  months[moment().month()].slice(1)}
              </CustomButton>
              <CustomButton onClick={() => handlePeriodoPreDefinido(1)}>
                {months[moment().subtract(1, "month").month()]
                  .charAt(0)
                  .toUpperCase() +
                  months[moment().subtract(1, "month").month()].slice(1)}
              </CustomButton>
              <CustomButton onClick={() => handlePeriodoPreDefinido(2)}>
                {months[moment().subtract(2, "month").month()]
                  .charAt(0)
                  .toUpperCase() +
                  months[moment().subtract(2, "month").month()].slice(1)}
              </CustomButton>
              <ButtonClear onClick={handleClear}>Limpar</ButtonClear>
            </ButtonGroup>
          </CalendarContainer>
        )}
      />
    </DatePickerContainer>
  );
};

export default CustomDatePicker;

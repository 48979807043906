import api from "../util/api";
import { notifyWarn, notifySuccess } from "../components/message/message";
import * as actions from "../store/modules/parametrosAutomacao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

import { maskDate } from "../util/masks";

export async function get() {
  setLoading(true);
  const data = await api.get("Automacao/GetAll");
  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.getParametros(data.content);
}
export async function getFilters(item) {

  let url = `Automacao/GetAll?`;

  if (item.Arquivo !== "") {
    url = url + `&Arquivo=${item.Arquivo}`;
  }

  if (item.BancosId !== "0") {
    url = url + `&BancoId=${item.BancosId}`;
  }

  if (item.LayoutPlanilhaId !== "0") {
    url = url + `&LayoutPlanilhaId=${item.LayoutPlanilhaId}`;
  }

  if (item.StatusId !== "0") {
    url = url + `&Status=${item.StatusId}`;
  }
  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.getParametros(data.content);
}

export async function getBancos() {
  const data = await api.get("Bancos");

  if (!data.success) {
    return;
  }

  actions.getBancos(data.content);
}

export async function getLayoutes() {
  setLoading(true);
  const data = await api.get("LayoutPlanilha");
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content = data.content.map((layout) => {
    return {
      id: layout.id,
      descricao: layout.descricao,
    };
  });

  actions.getLayoutes(data.content);
}

export async function getRecorrencias() {
  setLoading(true);
  const data = await api.get("RecorrenciaAutomacao");
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content = data.content.map((recorrencia) => {
    return {
      id: recorrencia.id,
      descricao: recorrencia.descricao,
    };
  });

  actions.getRecorrencias(data.content);
}

export async function getById(id) {
  setLoading(true);
  const data = await api.get(`Automacao/GetById?id=${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  let newContent = {
    id: id,
    url: data.content.url,
    instituicao: data.content.instituicao,
    layoutPlanilhaId: data.content.layoutPlanilha.id,
    recorrenciaId: data.content.idRecorrencia,
    intervalo: data.content.intervalo,
    horaInicio: data.content.horaInicio,
    horaFim: data.content.horaFim,
    usuario: data.content.usuarioAutomacao,
    senha: "",
    log: [],
    diaDaSemana: data.content.diaDaSemana,
    diaDoMes: data.content.diaDoMes,
  };

  actions.item(newContent);
}

export async function getLog(id, page, pageSize) {
  setLoading(true);
  const data = await api.get(
    `LogsAutomacao?AutomacaoId=${id}&pagina=${page}&registro=${pageSize}`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  const newData = data.content.registros.map((log) => {
    return {
      status: log.status ? "Sucesso" : "Erro",
      dataCriacao: maskDate(log.dataCriacao, "DD-MM-YYYY HH:mm"),
      detalhes: log.detalhes,
    };
  });

  actions.setItem("log", newData);
  actions.getAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function update(item) {
  if (item.usuario.trim() === "") {
    notifyWarn("Nome do usuário não pode estar em branco");
    return;
  }

  if (item.url?.trim() === "") {
    notifyWarn("URL não pode estar em branco");
    return;
  }

  if (item.layoutPlanilhaId === "0") {
    notifyWarn("Layout da planilha não pode estar em branco");
    return;
  }

  if (item.recorrenciaId === "0") {
    notifyWarn("Recorrencia não pode estar em branco");
    return;
  }

  if (
    item.recorrenciaId === "62bfebc3-64d0-4c21-98ab-08d9f7a3b6c6" &&
    item.intervalo === ""
  ) {
    notifyWarn("Intervalo não pode estar em branco");
    return;
  }
  if (
    item.recorrenciaId === "f164e5a0-73f2-4fb2-98ae-08d9f7a3b6c6" &&
    (item.diaDaSemana === "0" || item.diaDaSemana === undefined)
  ) {
    notifyWarn("Dia da Semana não pode estar em branco");
    return;
  }
  if (
    item.recorrenciaId === "2b69b83a-4102-485c-98ad-08d9f7a3b6c6" &&
    (item.diaDoMes === "0" || item.diaDoMes === undefined)
  ) {
    notifyWarn("Mês do Ano não pode estar em branco");
    return;
  }

  const body = {
    id: item.id,
    usuarioAutomacao: item.usuario,
    senhaAutomacao: item.senha === "" ? null : item.senha,
    horaInicio: item.horaInicio,
    horaFim: item.horaFim,
    intervalo: item.intervalo,
    idRecorrencia: item.recorrenciaId,
    layoutId: item.layoutPlanilhaId,
    url: item.url,
    diaDoMes: item.diaDoMes,
    diaDaSemana: item.diaDaSemana,
  };

  setLoading(true);
  const data = await api.put(`Automacao/${item.id}`, body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Parâmetros de automação atualizados");
  actions.cleanRegister();
  actions.setActiveTab("Search");
  get();
}

export async function inativarAutomacao(id, status) {
  setLoading(true);
  const data = await api.delete(`automacao?id=${id}&status=${!status}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess(`Automação ${status ? "inativado" : "ativado"} com sucesso`);
  get();
}

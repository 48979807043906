import React, { useEffect, useMemo, useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination } from "react-table";
import { COLUMNS } from "../constants/columns";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import * as actions from "../../../store/modules/parametrosAutomacao/actions";
import * as controller from "../../../controllers/parametrosAutomacaoController";
import { Checkbox } from "@material-ui/core";
import { Select } from "../../../components/Inputs/Input";
const Search = () => {
  const { item, parametros, bancos, status, layoutesPlanilha } = useSelector(
    (state) => state.parametrosAutomacao
  );
  const columns = useMemo(() => COLUMNS, []);
  const data = parametros;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [rows, setRows] = useState(0);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    controller.get();
  }, []);

  useEffect(() => {
    setRows(page.length);
  }, [page]);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
  };

  async function handleUpdate(id) {
    await controller.getById(id);
    await controller.getLog(id, 1, 10);
    actions.setActiveTab("Register");
  }

  function seeRegister(id) {
    actions.setSeeRegister(true);
    handleUpdate(id);
  }
  async function onChangeFiltros(value, campo) {
    console.log(`Campo: ${campo}, Valor: ${value}`);
    actions.setItem(campo, value);
  }

  useEffect(() => {
    controller.getBancos();
    controller.getLayoutes();
    controller.get();
  }, []);

  function handleInitialPage() {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }

  const pageCount = Math.ceil(data.length / pageSize);
  return (
    <>
      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Form className="search-form">
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Arquivo:</Label>
                  <Input
                    type="text"
                    placeholder="Busque um arquivo..."
                    value={item.Arquivo}
                    onChange={(e) => {
                      onChangeFiltros(e.target.value, "Arquivo");
                    }}
                    autoComplete="true"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Instituição:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.BancosId }}
                    select={{
                      value: "id",
                      label: "nome",
                    }}
                    options={bancos}
                    onChange={(e) => {
                      onChangeFiltros(e.value, "BancosId");
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Layout:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.LayoutPlanilhaId }}
                    select={{
                      value: "id",
                      label: "descricao",
                    }}
                    options={layoutesPlanilha}
                    onChange={(e) =>
                      onChangeFiltros(e.value, "LayoutPlanilhaId")
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.StatusId }}
                    select={{
                      value: "id",
                      label: "descricao",
                    }}
                    options={status}
                    onChange={(e) => {
                      onChangeFiltros(e.value, "StatusId");
                    }}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginTop: "15px", marginBottom: "10px" }}>
              <Col md="auto">
                <Button
                  className="clearButton"
                  onClick={() => {
                    actions.cleanRegister();
                  }}
                >
                  Limpar
                </Button>
              </Col>
              <Col md="auto">
                <Button
                  className="purpleButton"
                  style={{ width: "150px", marginRight: "10px" }}
                  onClick={() => {
                    controller.getFilters(item);
                    gotoPage(0);
                    setInitialPage(true);
                    setSelectedPage(0);
                  }}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Form>
          <Col>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {column.render("Header")}
                        <span className="float-right">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsIcons.BsArrowDown
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            ) : (
                              <BsIcons.BsArrowUp
                                id="OrdenarAsc"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            )
                          ) : (
                            <BsIcons.BsArrowDownUp
                              id="Ordenar"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                    <th style={{ textAlign: "center" }}>Ações</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="text-center" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <>
                          <Checkbox
                            checked={row.original.status}
                            style={{ color: "green" }}
                            size={"small"}
                            onClick={(e) =>
                              controller.inativarAutomacao(
                                row.original.id,
                                row.original.status
                              )
                            }
                          />
                          <BiIcons.BiEdit
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                              marginRight: "7px",
                            }}
                            onClick={() => handleUpdate(row.original.id)}
                          />
                          <AiIcons.AiOutlineEye
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                              marginRight: "7px",
                            }}
                            onClick={() => seeRegister(row.original.id)}
                          />
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row className="pt-4">
              <Col>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando {rows} de {parametros.length}
                </span>
              </Col>
              <Col md={8}>
                <Row className="float-right">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage();
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Search;

import { STORE_ID, TYPES } from './types'

const INITIAL_STATE = {
    matriculas: [],
    contasCorrenteSelected: [],
    seeRegister: false,
    isEditando: false,
    isExcluindo: false,
    conta: {
        id: null,
        instituicaoFinanceiraId: '0',
        nomeAgencia: '',
        numero: '',
        matricula: '0',
        matriculaNome: '',
        chavePix: '',
        tipoPixId: '0',
        tipoConta: '0',
        digitoConta: '',
        digitoAgencia: '',
        flagPadrao: false,
        cdContaCorrenteW3ERP: '',
        principal: false,
    },
    tiposPix: [],
    tiposConta: [],
}

export default function contaCorrente(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_MATRICULAS_SELECTED:
                    return { ...state, conta: {...state.conta, matriculasContaCorrente: action.value} }

                case TYPES.CONTA_CORRENTE:
                    return { ...state, contasCorrenteSelected: action.value }

                case TYPES.SET_BANCOS:
                    return { ...state, bancos: action.value }

                case TYPES.SET_MATRICULAS:
                    return { ...state, matriculas: action.value }

                case TYPES.SET_TIPOS_PIX:
                    return { ...state, tiposPix: action.value }

                case TYPES.SET_TIPOS_CONTA:
                    return { ...state, tiposConta: action.value }

                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }

                case TYPES.SET_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }

                case TYPES.CLEAN_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }

                case TYPES.SETCONTA:
                    return { ...state, conta: action.value }

                case TYPES.SET_CAMPO_CONTA:
                    return { ...state, conta: {...state.conta, [action.field]: action.value} }

                case TYPES.CLEAN:
                    return {
                        ...state,
                        conta:  {
                            id: null,
                            instituicaoFinanceiraId: '0',
                            nomeAgencia: '',
                            numero: '',
                            matricula: '0',
                            chavePix: '',
                            tipoPixId: '0',
                            tipoConta: '0',
                            digitoConta: '',
                            digitoAgencia: '',
                            matriculasContaCorrente: [],
                            flagPadrao: false,
                            cdContaCorrenteW3ERP: '',
                            principal: false,
                        }
                    }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        contasCorrenteSelected: [],
                        invalid: INITIAL_STATE.invalid,
                        conta: INITIAL_STATE.conta
                    }
                case TYPES.SET_IS_EDITANDO:
                    return {...state, isEditando: action.value}
                case TYPES.SET_IS_EXCLUINDO:
                    return {...state, isExcluindo: action.value}
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}

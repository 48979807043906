import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import TableEmail from "./components/TableEmail";
import * as contatoActions from "../../../../../store/modules/personas/contato_store/actions";
import TableTelefone from "./components/TableTelefone";
import * as AiIcons from "react-icons/ai";
import * as contatoController from "../../../../../controllers/personasController/contatosController";
import * as personasController from "../../../../../controllers/personasController/personasController";
import { Select, Switch } from "../../../../../components/Inputs/Input";
import { maskNumber, maskTelefone } from "../../../../../util/masks";
import { notifyWarn } from "../../../../../components/message/message";

const Contato = () => {
  const {
    emails,
    telefones,
    telefone,
    seeRegister,
    email,
    tiposEmails,
    tiposTelefones,
  } = useSelector((state) => state.personasContato);
  const { informacao } = useSelector((state) => state.personasInformacoes);

  useEffect(() => {
    contatoController.getTiposEmail();
    contatoController.getTiposTelefone();
  }, []);

  async function addContato(isTelefone) {
    if (isTelefone) {
      if (telefone.ddd === "") {
        return notifyWarn("DDD não pode estar em branco");
      }
      if (telefone.numero === "") {
        return notifyWarn("Telefone não pode estar em branco");
      }
      if (telefone.tipoTelefoneId === "0") {
        return notifyWarn("Selecione o tipo de telefone");
      }
      await personasController.salveContato(
        informacao.id,
        telefone,
        isTelefone
      );
    } else {
      if (email.nome === "") {
        return notifyWarn("Email não pode estar em branco");
      }
      if (email.tipoEmailId === "0") {
        return notifyWarn("Selecione o tipo do email");
      }
      await personasController.salveContato(informacao.id, email, isTelefone);
    }
  }

  function onChangeEmail(value, field) {
    contatoActions.setEmail({ ...email, [field]: value });
  }

  function onChangeEndereco(value, field) {
    contatoActions.setTelefone(field, value);
  }

  return (
    <>
      <Card
        className="contact-card p-3 m-4"
        style={{ backgroundColor: "var(--color-quase-branco)" }}
      >
        <Col>
          <Row className="align-items-center">
          <Col md={3}>
            <FormGroup style={{ width: "218px" }}>
              <Label for="emailPersona">Email</Label>
              <Input
                value={email.nome}
                disabled={seeRegister}
                onChange={(e) => onChangeEmail(e.target.value, "nome")}
                id="emailPersona"
                type="email"
                maxLength="150"
                placeholder="Digite aqui..."
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup style={{ width: "160px", marginLeft: "25px" }}>
              <Label for="emailSelect">Tipo do email</Label>
              <Select
                first="Selecione"
                disabled={seeRegister}
                onChange={(e) => onChangeEmail(e.value, "tipoEmailId")}
                value={{ value: email.tipoEmailId }}
                select={{ value: "id", label: "nome" }}
                options={tiposEmails}
                isSearchable={true}
              />
            </FormGroup>
            </Col>
            <Col md={3}>
            <FormGroup style={{ width: "163px", marginLeft: "25px" }}>
              <Switch
                componentTop="Email principal"
                checked={email.flagPrincipal}
                onChange={(e) =>
                  onChangeEmail(!email.flagPrincipal, "flagPrincipal")
                }
                className="switch"
                disabled={seeRegister}
              />
            </FormGroup>
            </Col>
          </Row>
          <Label className="mt-2">
            <AiIcons.AiOutlinePlusCircle
              style={{
                fontSize: "1.5rem",
                marginRight: "7px",
                color: "green",
                cursor: "pointer",
              }}
              onClick={() => addContato(false)}
              disabled={seeRegister}
            />
            Adicionar Email
          </Label>
        </Col>
      </Card>

      {emails.length > 0 && <TableEmail emails={emails} />}

      <Card
        className="contact-card p-3 m-4"
        style={{ backgroundColor: "var(--color-quase-branco)" }}
      >
        <Col>
          <Row className="align-items-center">
          <Col md={2}>
            <FormGroup style={{ width: "90px" }}>
              <Label for="ddd">DDD</Label>
              <Input
                value={telefone.ddd}
                disabled={seeRegister}
                onChange={(e) =>
                  onChangeEndereco(maskNumber(e.target.value), "ddd")
                }
                id="dddPersona"
                type="text"
                placeholder="DDD"
                maxLength="2"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup style={{ width: "185px", marginLeft: "25px" }}>
              <Label for="telefone">Telefone</Label>
              <Input
                value={telefone.numero}
                disabled={seeRegister}
                onChange={(e) =>
                  onChangeEndereco(maskTelefone(e.target.value), "numero")
                }
                id="telefonePersona"
                type="text"
                placeholder="Digite aqui..."
                maxLength="10"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup style={{ width: "163px", marginLeft: "25px" }}>
              <Label for="telefoneSelect">Tipo de Telefone</Label>
              <Select
                first="Selecione"
                disabled={seeRegister}
                onChange={(e) => onChangeEndereco(e.value, "tipoTelefoneId")}
                value={{ value: telefone.tipoTelefoneId }}
                select={{ value: "id", label: "descricao" }}
                options={tiposTelefones}
                isSearchable={true}
              />
            </FormGroup>
          </Col>

            <Col md={3}>
              <FormGroup>
                <Switch
                  componentTop="Telefone principal"
                  checked={telefone.principal}
                  onChange={(e) =>
                    onChangeEndereco(!telefone.principal, "principal")
                  }
                  className="switch"
                  disabled={seeRegister}
                />
              </FormGroup>
            </Col>
          </Row>
          <Label className="mt-2">
            <AiIcons.AiOutlinePlusCircle
              style={{
                fontSize: "1.5rem",
                marginRight: "7px",
                color: "green",
                cursor: "pointer",
              }}
              onClick={() => addContato(true)}
              disabled={seeRegister}
            />
            Novo Telefone
          </Label>
        </Col>
      </Card>
      {telefones.length > 0 && <TableTelefone telefones={telefones} />}
    </>
  );
};

export default Contato;

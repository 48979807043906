import React, { useEffect } from "react";
import * as BiIcons from "react-icons/bi";
import * as contatoActions from "../../../../../../store/modules/personas/contato_store/actions";
import * as personasController from "../../../../../../controllers/personasController/personasController";
import { useSelector } from "react-redux";

function TableTelefone({ telefones }) {
  const { seeRegister, tiposTelefones } = useSelector(
    (state) => state.personasContato
  );

  const data = telefones;

  async function handleRemoverItem(index, id) {
    const isSuccessed = await personasController.deleteTelefone(id);
    if (isSuccessed) {
      const arrTelefones = telefones;
      arrTelefones.splice(index, 1);
      contatoActions.setTelefones(arrTelefones);
    }
  }
  useEffect(() => {
    console.log("Telefones recebidos:", telefones); // Debug: Verifique os valores ao receber a prop
  }, [telefones]);

  return (
    <>
    
      <table style={{ marginTop: "20px", width: "95%", marginLeft: "2%" }}>
        <thead>
          <tr>
            <th>DDD</th>
            <th>Telefone</th>
            <th>Tipo de Telefone</th>
            <th>Telefone Principal</th>
            <th style={{ textAlign: "center" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
              const tipoTelefone = tiposTelefones.find(
                  (e) => e.id === row.tipoTelefoneId
                );
                console.log(row.principal);
            return (
              <tr key={index}>
                <td>{row.ddd}</td>
                <td>{row.numero}</td>
                <td>{tipoTelefone?.descricao || "Desconhecido"}</td>
                <td>{(row.principal) ? "Sim" : "Não"}</td>
                <td style={{ textAlign: "center" }}>
                  {!seeRegister && (
                    <BiIcons.BiTrash
                      style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#C4C7CA",
                      }}
                      onClick={() => handleRemoverItem(index, row.id)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default TableTelefone;

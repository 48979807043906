import { maskMoeda } from "../../../util/masks";

export const COLUMNS = [
    {
        Header: "Favorecido",
        accessor: "nomePessoa"
    },
    {
        Header: "Hierarquia",
        accessor: "dsHierarquia"
    },
    {
        Header: "Status do Favorecido",
        accessor: "statusFavorecido"
    },
    {
        Header: "Meta Total",
        accessor: "metaTotal",
        Cell: ({ value }) => {
            return value?.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        },
    },
    {
        Header: "Produção Total $",
        accessor: "producaoTotal",
        Cell: ({ value }) => {
            return value?.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        },
    },
    {
        Header: "Atingimento s/ meta %",
        accessor: "percAtingimentoSobreMeta"
    },
    {
        Header: "Total Pago $",
        accessor: "totalPago",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Total Pago s/ Produção %",
        accessor: "percTotalPagoSobreProducao",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Total Pago s/ Receita %",
        accessor: "percTotalPagoSobreReceita"
    },
    {
        Header: "Receita Total $",
        accessor: "receitaTotal",
        Cell: ({ value }) => {
            return value?.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        },
    },
    {
        Header: "Receita Total %",
        accessor: "percReceitaTotal",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Repasse de Comissão $",
        accessor: "valorComissaoPagaOriginal",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Repasse de Comissão %",
        accessor: "percComissaoPaga",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Status Comissão",
        accessor: "contaPagarStatusIntegracaoRepasse"
    },
    {
        Header: "Prêmio Superação $",
        accessor: "valorSuperacaoOriginal",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Prêmio Superação %",
        accessor: "percPremioPagoSuperacao",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Status Superação",
        accessor: "contaPagarStatusIntegracaoSuperacao"
    },
    {
        Header: "Prêmio Produtividade $",
        accessor: "valorProdutividadeOriginal",
        Cell: ({ value }) => {
            return maskMoeda(Number(value).toFixed(2));
        },
    },
    {
        Header: "Prêmio Produtividade %",
        accessor: "percPremioPagoProdutividade"
    },
    {
        Header: "Status Produtividade",
        accessor: "contaPagarStatusIntegracaoProdutividade"
    },
    
]


import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function setItem(value) {
  dispatch({
    type: TYPES.SET_ITEM,
    value,
  });
}

export function item(field, value) {
  dispatch({
    type: TYPES.ITEM,
    field,
    value,
  });
}

export function setItems(value) {
  dispatch({
    type: TYPES.ITEMS,
    value,
  });
}

export function setTotals(value) {
  dispatch({
    type: TYPES.TOTALS,
    value,
  });
}

export function getAllItems(value) {
  dispatch({
    type: TYPES.GET_ALL_ITENS,
    value,
  });
}

export function setCanais(value) {
  dispatch({
    type: TYPES.SET_CANAIS,
    value,
  });
}

export function setCampanhas(value) {
  dispatch({
    type: TYPES.SET_CAMPANHA,
    value,
  });
}

export function setHierarquias(value) {
  dispatch({
    type: TYPES.SET_HIERARQUIAS,
    value,
  });
}

export function setModalConsultores(campo, value) {
  dispatch({
    type: TYPES.SET_MODAL_CONSULTORES,
    campo,
    value,
  });
}

export function setConsultores(value) {
  dispatch({
    type: TYPES.SET_CONSULTORES,
    value,
  });
}

export function setGetAllConsultores(value) {
  dispatch({
    type: TYPES.SET_GET_ALL_CONSULTORES,
    value,
  });
}

export function setCentrosCusto(value) {
  dispatch({
    type: TYPES.SET_CENTROS_CUSTO,
    value,
  });
}

export function setEmpresasFuncionario(value) {
  dispatch({
    type: TYPES.SET_EMPRESAS_FUNCIONARIO,
    value,
  });
}

export function setCampanhaTipo(value) {
  dispatch({
    type: TYPES.SET_CAMPANHA_TIPO,
    value,
  });
}

export function setStatusIntegracao(value) {
  dispatch({
    type: TYPES.SET_STATUS_INTEGRACAO,
    value,
  });
}

export function setStatusFavorecido(value) {
  dispatch({
    type: TYPES.STATUS_FAVORECIDO,
    value,
  });
}

export function setSistemas(value) {
  dispatch({
    type: TYPES.SET_SISTEMAS,
    value,
  });
}

export function cleanSearch() {
  dispatch({
    type: TYPES.CLEAN_SEARCH,
  });
}
export function cleanAllSearch() {
  dispatch({
      type: TYPES.CLEAN_ALL_SEARCH
  })
}
export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE,
    value,
  });
}
export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER,
  });
}

export function enableCheckboxes(value) {
  dispatch({
    type: TYPES.ENABLE_CHECKBOXES,
    value,
  });
}


export function cleanModalEdicao() {
  dispatch({
    type: TYPES.CLEAN_MODAL_EDICAO
  });
}

export function setModalEdicao(value) {
  dispatch({
    type: TYPES.SET_MODAL_EDICAO,
    value,
  });
}

export function setModalEdicaoField(value, field) {
  dispatch({
    type: TYPES.SET_MODAL_EDICAO_FIELD,
    value,
    field
  });
}

export function setSelectedItems(value) {
  dispatch({
    type: TYPES.SET_SELECTED_ITEMS,
    value
  });
}

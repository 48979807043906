import api from '../util/api'
import * as actions from '../store/modules/inconsistenciaComissaoRecebida/actions'
import { setLoading } from '../store/modules/globalLoading/actions'
import moment from 'moment'

export async function getQuantidade(inconsistencias) {
    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-comissao-recebida/quantidades/possivel-corrigir`)
    setLoading(false)

    if (!data.success) {
        return
    }

    inconsistencias.forEach(inconsistencia => {
        let inconsistenciaFound = data.content.find((e) => inconsistencia.id === e.id)
        if (inconsistenciaFound) {
            inconsistencia.quantidade = inconsistenciaFound.quantidade
        } else {
            inconsistencia.quantidade = 0
        }
    })

    actions.setTabs(inconsistencias)
};


export async function getInconsistencias(tab) {
    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-comissao-recebida/tipo-inconsistencia/${tab.id}`)

    if (!data.success) {
        return setLoading(false)
    }

    if (tab.tab === "1") {
        const proposta = data.content.map((item) => {
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo,
                planilhaId: item.planilhaId
            })
        });
        actions.setProposta(proposta)
        return setLoading(false)
    }

    if (tab.tab === "2") {
        const lojaBanco = data.content.map((item) => {
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setLojaBanco(lojaBanco)
        return setLoading(false)
    }
    if (tab.tab === "3") {
        const tipoComissao = data.content.map((item) => {
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo,
                planilhaId: item.planilhaId
            })
        });
        actions.setTiposcomissao(tipoComissao)
        return setLoading(false)
    }
    if (tab.tab === "4") {
        const produtoBanco = data.content.map((item) => {
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo,
                planilhaId: item.planilhaId
            })
        });
        actions.setProdutoBanco(produtoBanco)
        return setLoading(false)
    }
}

export async function getInconsistenciasPaginado(numeroProposta, page) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-comissao-recebida/proposta-paginada?pagina=${page}${numeroProposta !== '' ? `&numeroProposta=${numeroProposta}` : ''}`)

    if (!data.success) {
        return setLoading(false)
    }


    const propostas = data.content.registros.map((item) => {
        return ({
            id: item.id,
            descricao: item.descricao,
            bancoId: item.bancoId,
            nomeBanco: item.nomeBanco,
            nomeArquivo: item.nomeArquivo,
            parametro: item.parametro,
            planilhaId: item.planilhaId,
            produto: item.produto
        })
    });
    actions.setProposta(propostas)
    actions.getAllItems({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    })
    if (page === 1) {
        actions.setIsFirstPage(true)
    } else {
        actions.setIsFirstPage(false)
    }
    return setLoading(false)
}

export async function correcaoInconsistencia(inconsistencia, tab) {
    let body = {
        tipoInconsistenciaId: tab.id,
        descricao: inconsistencia.descricao,
        bancoId: inconsistencia.bancoId,
        parametro: inconsistencia.parametro,
        planilhaId: inconsistencia.planilhaId,
        produto: inconsistencia.produto
    }
    body.planilhaId = inconsistencia.planilhaId


    setLoading(true)
    const data = await api.post("inconsistencia-sistema-comissao-recebida/correcao", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    getInconsistencias(tab)
}

export const downloadPlanilha = async () => {
    try {
        setLoading(true);
        const response = await api.get(`inconsistencia-sistema-comissao-recebida/download-planilha-propostas`);
    
        if (!response.success) {
          return false;
        }
    
        const byteCharacters = atob(response?.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
    
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        const dataAtual = moment().format('YYYY-MM-DD');
        link.download = `inconsistencia-comissao-recebida-${dataAtual}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Erro ao fazer o download da planilha');
        return false;
      } finally {
        setLoading(false);
      }
}
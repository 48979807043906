import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function item(value) {
  dispatch({
    type: TYPES.ITEM,
    value,
  });
}

export function setItem(campo, value) {
  dispatch({
    type: TYPES.SET_ITEM,
    campo,
    value,
  });
}

export function getParametros(value) {
  dispatch({
    type: TYPES.GET_PARAMETROS,
    value,
  });
}

export function getRecorrencias(value) {
  dispatch({
    type: TYPES.GET_RECORRENCIAS,
    value,
  });
}

export function getLayoutes(value) {
  dispatch({
    type: TYPES.GET_LAYOUTES,
    value,
  });
}

export function getBancos(value) {
  dispatch({
    type: TYPES.GET_BANCOS,
    value,
  });
}

export function setStatus(value) {
  dispatch({
    type: TYPES.SET_STATUS,
    value,
  });
}

export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER,
  });
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE,
    value,
  });
}

export function getAllItems(value) {
  dispatch({
    type: TYPES.GET_ALL_ITEMS,
    value,
  });
}

import api from "../util/api";
import * as actions from "../store/modules/comissao/actions";
import {
  notifyWarn,
  notifySuccess,
  notifyInfo,
} from "../components/message/message";
import { maskDate, maskMoeda, removeMaskMoeda } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get(pesquisa, pageSize, page) {
  var url = `tabelaComissao/Paginado?pagina=${page}&registros=${pageSize}&statusTabela=${pesquisa.statusTabela}`;

  if (pesquisa.descricao !== "") url = url + `&Descricao=${pesquisa.descricao}`;
  if (pesquisa.codInstituicao !== "0")
    url = url + `&BancoId=${pesquisa.codInstituicao}`;
  if (pesquisa.produtoRiberId !== "0")
    url = url + `&ProdutoId=${pesquisa.produtoRiberId}`;
  if (pesquisa.codConvenio !== "0")
    url = url + `&ConvenioId=${pesquisa.codConvenio}`;
  if (pesquisa.codOperacao !== "0")
    url = url + `&TipoOperacaoId=${pesquisa.codOperacao}`;
  if (pesquisa.dataInicioVigencia !== "")
    url = url + `&DtInicioVig=${pesquisa.dataInicioVigencia}`;
  if (pesquisa.dataFimVigencia !== "")
    url = url + `&DtFimVig=${pesquisa.dataFimVigencia}`;

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  let body = [];
  data.content.registros.forEach((regs) => {
    const inicioVig = regs.dtInicioVig
      ? regs.dtInicioVig.substring(8, 10) +
        "/" +
        regs.dtInicioVig.substring(5, 7) +
        "/" +
        regs.dtInicioVig.substring(0, 4)
      : "";
    const fimVig = regs.dtFimVig
      ? regs.dtFimVig.substring(8, 10) +
        "/" +
        regs.dtFimVig.substring(5, 7) +
        "/" +
        regs.dtFimVig.substring(0, 4)
      : "";
    body.push({
      id: regs.id,
      descricao: regs.descricao,
      banco: regs.banco.nome,
      produto: regs.produtoBanco.produto.nome,
      convenio: regs.convenioBanco.convenio.descricao,
      vigencia: `${inicioVig} - ${fimVig}`,
    });
  });
  actions.setComissoes(body);
  actions.setAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getConveniosCadastro(id) {
  if (id === "0") return;

  const data = await api.get(
    `ConveniosBanco/ConvenioInstituicao?idInstituicao=${id}`
  );

  if (!data.success) {
    return;
  }
  actions.setTiposConveniosCadastro(data.content);
}

export async function getTiposOperacaoCadastro(id) {
  if (id === "0") return;

  const data = await api.get(
    `tipos-operacao-banco/TipoOperacaoInstituicao?idInstituicao=${id}`
  );

  if (!data.success) {
    return;
  }
  actions.setTiposOperacaoCadastro(data.content);
}

export async function getProdutosCadastro(id) {
  if (id === "0") return;

  const data = await api.get(
    `ProdutoBanco/ProdutoInstituicao?idInstituicao=${id}`
  );

  if (!data.success) {
    return;
  }
  actions.setProdutosCadastro(data.content);
}

export async function getInstituicoes() {
  const data = await api.get("bancos");

  if (!data.success) {
    return;
  }
  actions.setInstituicoes(data.content);
}

export async function getProdutosInstituicao(bancoId) {
  const data = await api.get(
    `ProdutoBanco/ProdutoInstituicao?idInstituicao=${bancoId}`
  );

  if (!data.success) {
    return;
  }
  actions.setProdutosInstituicao(data.content);
}

export async function getConveniosInstituicao(bancoId) {
  const data = await api.get(
    `ConveniosBanco/ConvenioInstituicao?idInstituicao=${bancoId}`
  );

  if (!data.success) {
    return;
  }
  actions.setConveniosInstituicao(data.content);
}

export async function getTiposOperacaoInstituicao(bancoId) {
  const data = await api.get(
    `tipos-operacao-banco/TipoOperacaoInstituicao?idInstituicao=${bancoId}`
  );

  if (!data.success) {
    return;
  }
  actions.setTiposInstituicao(data.content);
}

export async function getProdutos() {
  const data = await api.get("produtoBanco");

  if (!data.success) {
    return;
  }
  actions.setProdutos(data.content);
}

export async function getProdutosRiber() {
  const data = await api.get("Produtos");

  if (!data.success) {
    return;
  }
  actions.setProdutosRiber(data.content);
}

export async function getConvenios() {
  const data = await api.get("convenios");

  if (!data.success) {
    return;
  }
  actions.setConvenios(data.content);
}

export async function getTiposComissaoBanco(bancoId) {
  setLoading(true);
  const data = await api.get(
    `tipoComissao/GetByInstituicao?idInstituicao=${bancoId}`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  let dataFormated = [];

  data.content.forEach((comissao) => {
    dataFormated.push({
      id: comissao.tiposComissaoBanco[0].id,
      descricao: comissao.descricao,
      unidadeComissao: comissao.tiposComissaoBanco[0].unidadeComissao,
    });
  });

  actions.setTiposComissaoBanco(dataFormated);
}

export async function getTiposComissaoProdutoBanco(produtoBancoId) {
  setLoading(true);
  const data = await api.get(
    `ProdutoBanco/${produtoBancoId}/tipo-comissao-banco`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  let dataFormated = [];

  data.content.forEach((comissao) => {
    dataFormated.push({
      id: comissao.id,
      descricao: comissao.descricao,
      unidadeComissao: comissao.unidadeComissao,
    });
  });

  actions.setTiposComissaoBanco(dataFormated);
}

export async function getTiposOperacao() {
  const data = await api.get("tipos-operacao");

  if (!data.success) {
    return;
  }

  actions.setTiposOperacao(data.content);
}

export async function getById(id) {
  setLoading(true);
  const data = await api.get(`tabelaComissao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content.tabelaComissaoEventos.forEach((e) => {
    e.valor = maskMoeda(Number(e.valor).toFixed(2));
    e.unidade = e.tiposComissaoBanco.unidade;
  });

  const dataUpdate = {
    id: data.content.id,
    descricao: data.content.descricao,
    bancoId: data.content.bancoId,
    produtoBancoId: data.content.produtoBancoId,
    convenioBancoId: data.content.convenioBancoId,
    tipoOperacaoBancoId: data.content.tipoOperacaoBancoId,
    codigoTabela: data.content.codigoTabela,
    dtInicioVig: maskDate(data.content.dtInicioVig, "YYYY-MM-DD"),
    dtFimVig: data.content.dtFimVig
      ? maskDate(data.content.dtFimVig, "YYYY-MM-DD")
      : "",
    tabelaComissaoEventos: data.content.tabelaComissaoEventos,
  };

  getProdutosCadastro(dataUpdate.bancoId);
  getConveniosCadastro(dataUpdate.bancoId);
  getTiposOperacaoCadastro(dataUpdate.bancoId);

  actions.setCopia(dataUpdate);
  actions.setRegistros(dataUpdate);
}

export async function create(registro) {
  if (registro.descricao.trim() === "") {
    actions.setInvalid("descricao");
    return notifyWarn("Descrição não pode estar em branco");
  }

  if (registro.bancoId === "0") {
    actions.setInvalid("bancoId");
    return notifyWarn("Selecione uma instituição");
  }

  if (registro.produtoBancoId === "0") {
    actions.setInvalid("produtoBancoId");
    return notifyWarn("Selecione um produto instituição");
  }

  if (registro.convenioBancoId === "0") {
    actions.setInvalid("convenioBancoId");
    return notifyWarn("Selecione um convênio instituição");
  }

  if (registro.tipoOperacaoBancoId === "0") {
    actions.setInvalid("tipoOperacaoBancoId");
    return notifyWarn("Selecione o tipo de operação instituição");
  }

  if (registro.dtInicioVig === "") {
    actions.setInvalid("dtInicioVig");
    return notifyWarn("Informe a Data Inicio da Vigência");
  }

  if (registro.dtFimVig !== "" && registro.dtInicioVig > registro.dtFimVig) {
    return notifyWarn("Data Inicial não pode ser maior que Data Final");
  }

  if (registro.codigoTabela === "") {
    actions.setInvalid("codigoTabela");
    return notifyWarn("Código da tabela não pode estar em branco");
  }

  if (registro.tabelaComissaoEventos.length === 0) {
    return notifyWarn("Insira ao menos um evento");
  }

  if (registro.dtFimVig === "") {
    registro.dtFimVig = null;
  }

  registro.tabelaComissaoEventos.forEach((e) => {
    e.valor = removeMaskMoeda(e.valor);
  });

  setLoading(true);
  const data = await api.post("tabelaComissao", registro);
  setLoading(false);

  if (!data.success) {
    return { valid: false };
  }

  notifySuccess("Tabela cadastrada");
  actions.cleanRegister();
  actions.setActiveTab("Search");
  get(
    {
      descricao: "",
      codInstituicao: "0",
      produtoRiberId: "0",
      codConvenio: "0",
      codOperacao: "0",
      dataInicioVigencia: "",
      dataFimVigencia: "",
      statusTabela: false,
    },
    10,
    1
  );
}

export async function update(registro, copia) {
  if (JSON.stringify(registro) === JSON.stringify(copia)) {
    notifyInfo("Nada foi alterado");
    return actions.setActiveTab("Search");
  }

  if (registro.descricao.trim() === "") {
    actions.setInvalid("descricao");
    return notifyWarn("Descrição não pode estar em branco");
  }

  if (registro.bancoId === "0") {
    actions.setInvalid("bancoId");
    return notifyWarn("Selecione nstituição");
  }

  if (registro.produtoBancoId === "0") {
    actions.setInvalid("produtoBancoId");
    return notifyWarn("Selecione um produto instituição");
  }

  if (registro.convenioBancoId === "0") {
    actions.setInvalid("convenioBancoId");
    return notifyWarn("Selecione convênio instituição");
  }

  if (registro.tipoOperacaoBancoId === "0") {
    actions.setInvalid("tipoOperacaoBancoId");
    return notifyWarn("Selecione o tipo de operação instituição");
  }

  if (registro.dtInicioVig === "") {
    actions.setInvalid("dtInicioVig");
    return notifyWarn("Informe a Data Inicio da Vigência");
  }

  if (registro.dtFimVig !== "" && registro.dtInicioVig > registro.dtFimVig) {
    return notifyWarn("Data Inicial não pode ser maior que Data Final");
  }

  if (registro.codigoTabela === "") {
    actions.setInvalid("codigoTabela");
    return notifyWarn("Código da tabela não pode estar em branco");
  }

  if (registro.tabelaComissaoEventos.length === 0) {
    return notifyWarn("Insira ao menos um evento");
  }

  if (registro.dtFimVig === "") {
    registro.dtFimVig = null;
  }

  let tabelaComissaoEventos = [];

  registro.tabelaComissaoEventos.forEach((e) => {
    tabelaComissaoEventos.push({
      id: e.id,
      impedeRepasse: e.impedeRepasse,
      mesesAte: e.mesesAte,
      mesesDe: e.mesesDe,
      tabelaComissaoId: e.tabelaComissaoId,
      tiposComissaoBancoId: e.tiposComissaoBanco
        ? e.tiposComissaoBanco.id
        : e.tiposComissaoBancoId,
      valor: removeMaskMoeda(e.valor),
    });
  });

  registro.tabelaComissaoEventos = tabelaComissaoEventos;

  setLoading(true);
  const data = await api.put(`tabelaComissao/${registro.id}`, registro);
  setLoading(false);

  if (!data.success) {
    return { valid: false };
  }

  notifySuccess("Tabela atualizada");
  actions.cleanRegister();
  actions.setActiveTab("Search");
}

export async function remove(id) {
  setLoading(true);
  const data = await api.delete(`tabelaComissao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Tabela removida");
  get(
    {
      descricao: "",
      codInstituicao: "0",
      produtoRiberId: "0",
      codConvenio: "0",
      codOperacao: "0",
      dataInicioVigencia: "",
      dataFimVigencia: "",
      statusTabela: false,
    },
    10,
    1
  );
}

export async function removeEvento(eventoId) {
  setLoading(true);
  const data = await api.delete(`tabelaComissao/evento/${eventoId}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Evento removido");
  return true;
}
